import { ifAuthenticated } from '@/router/before'

export const dialogRouteNames = {
  DialogUserRoute: 'DialogUserRoute'
}
const rn = dialogRouteNames

export const dialogRouteURLs = {
  [rn.DialogUserRoute]: '/dialogs/user'
}

export const dialogPages = [
  {
    path: dialogRouteURLs[rn.DialogUserRoute],
    name: rn.DialogUserRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'comments'],
        isDisplay: true
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Диалоги'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "DialogUserRoute" */ '@/views/dialogs/DialogUserRoute.vue'
      )
  }
]
