import { http } from '@/api/httpAxios'

export default class ChangeLogAPI {
  public static async getHasUnread() {
    const response = await http.get('/general/changelog/unread')
    return response.data
  }

  public static async getChangelog(limit: number, offset: number) {
    const response = await http.get('/general/changelog', {
      params: { limit, offset }
    })
    return response.data
  }
}
