export enum vacancyStatus {
  publicated = 'publicated',
  denied = 'denied',
  await = 'await',
  draft = 'draft'
}

export const vacancyStatusTranslation: { [k: string]: string } = {
  [vacancyStatus.draft]: 'Сохранена',
  [vacancyStatus.publicated]: 'Опубликована',
  [vacancyStatus.await]: 'На проверке',
  [vacancyStatus.denied]: 'Отклонена'
}

export enum editorType {
  full = 'full',
  partnerVacancy = 'partnerVacancy'
}
