

















import { Component, Vue } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component
export default class Logout extends Mappers {
  public $refs!: {
    logoutModal: BModal
  }

  private onLogout() {
    this.$refs.logoutModal.show()
  }

  private onLogoutOK() {
    this.$router.push('/logout')
  }
}
