import { ifAuthenticated } from '@/router/before'

export const vacancyRouteNames = {
  VacancyListRoute: 'VacancyListRoute',
  VacancyViewRoute: 'VacancyViewRoute',
  VacancyCardsRoute: 'VacancyCardsRoute',
  VacancyEmManagerApproveRoute: 'VacancyEmManagerApproveRoute'
}
const rn = vacancyRouteNames

export const vacancyRouteURLs = {
  [rn.VacancyListRoute]: '/partner/:partnerId/vacancies',
  [rn.VacancyViewRoute]: '/partner/:partnerId/vacancies/:vacancyId',
  [rn.VacancyCardsRoute]: '/vacancies',
  [rn.VacancyEmManagerApproveRoute]: '/vacancies/approve'
}

export const vacancyPages = [
  {
    path: vacancyRouteURLs[rn.VacancyListRoute],
    name: rn.VacancyListRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'briefcase'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Вакансии',
        displayName: 'Вакансии организации'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Вакансии'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "VacancyListRoute" */ '@/views/vacancies/VacancyListRoute.vue'
      )
  },
  {
    path: vacancyRouteURLs[rn.VacancyViewRoute],
    name: rn.VacancyViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Вакансии',
        displayName: 'Страница вакансии'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "VacancyViewRoute" */ '@/views/vacancies/VacancyViewRoute.vue'
      )
  },
  {
    path: vacancyRouteURLs[rn.VacancyCardsRoute],
    name: rn.VacancyCardsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'briefcase'],
        isDisplay: true
      },
      navbar: {
        group: 'Вакансии',
        displayName: 'Все вакансии'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "VacancyCardsRoute" */ '@/views/vacancies/VacancyCardsRoute.vue'
      )
  },

  {
    path: vacancyRouteURLs[rn.VacancyEmManagerApproveRoute],
    name: rn.VacancyEmManagerApproveRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'briefcase'],
        isDisplay: true
      },
      navbar: {
        group: 'Вакансии',
        displayName: 'Согласование'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "VacancyEmManagerApproveRoute" */ '@/views/vacancies/VacancyEmManagerApproveRoute.vue'
      )
  }
]
