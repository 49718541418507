export enum projectStatus {
  'active' = 'active', // Активен
  'notActive' = 'not active', // Неактивен
  'completed' = 'completed' // Завершен
}

export const projectStatusTranslation: { [k: string]: string } = {
  [projectStatus.active]: 'Активен',
  [projectStatus.notActive]: 'Неактивен',
  [projectStatus.completed]: 'Завершен'
}

export const projectStatusBadges: { [k: string]: string } = {
  [projectStatus.active]: 'Акт.',
  [projectStatus.notActive]: 'Не акт.',
  [projectStatus.completed]: 'Зав.'
}

export enum projectStudentStatus {
  applied = 'applied',
  participant = 'participant',
  denied = 'denied'
}

export const statusTranslate: { [k: string]: string } = {
  [projectStudentStatus.applied]: 'Подана заявка',
  [projectStudentStatus.participant]: 'Участник',
  [projectStudentStatus.denied]: 'В участии отказано'
}

export const projectStudentStatusBadges: { [k: string]: string } = {
  [projectStudentStatus.applied]: 'Подана',
  [projectStudentStatus.participant]: 'Участник',
  [projectStudentStatus.denied]: 'Отказано'
}
