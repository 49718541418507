import { baseURL } from '@/api/httpAxios'

export default class FakeUserAPI {
  public static fakeUserLogin(userId: number) {
    window.location.href = `${baseURL}/auth/view?userId=${userId}`
  }
  public static fakeUserLogout() {
    window.location.href = `${baseURL}/auth/view/logout`
  }
}
