












































































import { BModal } from 'bootstrap-vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { abstractField, GenField } from 'vue-form-generator'
import _ from 'lodash'
import { AgGridVue } from 'ag-grid-vue'
import UsersTable from '@/components/routes/admin/users/components/UsersTable.vue'
import { getUserText } from '@/utils/formatter'
import { GridApi, ICellRendererParams } from 'ag-grid-community'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import AgGridFactory from '@/api/factories/agGridFactory'
import { User } from '@/api/user'

const Mappers = Mixins(abstractField).extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({ components: { UsersTable, AgGridVue } })
export default class UserPicker extends Mappers {
  schema!: GenField
  value!: User | User[] | null
  text = ''
  table: any[] = []
  key = 0

  private gridApi!: GridApi

  $refs!: { userModal: BModal; deleteModal: BModal }

  @Watch('value')
  private updateText(newValue: User | User[] | null) {
    if (!this.isTableView) {
      if (!this.isMultiple) {
        this.text = getUserText(newValue as User)
      } else if (newValue) {
        this.text = (newValue as User[]).map(val => getUserText(val)).join(', ')
      } else {
        this.text = ''
      }
    } else {
      if (this.gridApi) {
        this.table = newValue as User[]
        this.resizeTable()
      }
    }
  }

  private created() {
    this.updateText(this.value)
  }

  private get isMultiple() {
    return this.schema.isMultiple ?? false
  }

  private get isTableView() {
    return this.schema.isTableView ?? false
  }

  private get buttonsDisabled() {
    return this.schema.buttonsDisabled || this.schema.disabled || false
  }

  private onReset() {
    if (this.isMultiple) {
      this.$refs.deleteModal.show()
    } else {
      this.value = null
      this.updateText(this.value)
    }
  }

  private onSelect() {
    this.$refs.userModal.show()
  }

  private onMultipleDelete(params: ICellRendererParams) {
    this.value = (this.value as User[]).filter(v => v.id !== params.data.id)
    this.updateText(this.value)
  }

  private onDoubleClick(node: any) {
    if (this.isMultiple) {
      if (!_.isArray(this.value)) {
        this.$set(this, 'value', [])
      } else {
        if (this.value.find(u => u.id === node.data.id)) {
          return
        }
      }
      ;(this.value as User[]).push(node.data)
      ;(this.value as User[]).sort((a, b) => (a.surname > b.surname ? 1 : -1))
    } else {
      this.$set(this, 'value', node.data)
    }
    this.$refs.userModal.hide()
    this.updateText(this.value)
  }

  private columnDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      width: 120,
      minWidth: 120,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      width: 120,
      minWidth: 120,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      width: 150,
      minWidth: 150,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Почта',
      field: 'email',
      colId: 'email',
      width: 150,
      minWidth: 150,
      filterParams: {
        buttons: ['reset']
      }
    }
  ]

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    cacheBlockSize: 15,
    rowBuffer: 100,
    pagination: true,
    paginationPageSize: 15,
    enableCellTextSelection: true,
    onGridReady: this.onGridReady,
    onGridSizeChanged: this.resizeTable
  }

  private resizeTable() {
    if (!this.isMobile) {
      this.gridApi.sizeColumnsToFit()
    }
  }

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
    api.setRowData(this.value as User[])
    this.resizeTable()
  }
}
