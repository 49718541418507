import { http } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'

export default class DictsAPI {
  public static getDepartments(): AxiosPromise {
    const params = new URLSearchParams()
    params.append('scopes', 'includeFaculty')
    params.append('scopes', 'includeLeaders')
    return http.get('/dicts/departments', { params })
  }

  public static getFaculties(): AxiosPromise {
    return http.get('/dicts/faculties')
  }
  public static getProjectTypes(): AxiosPromise {
    return http.get('/dicts/projectypes')
  }
  public static getEventTypes(): AxiosPromise {
    return http.get('/dicts/eventypes')
  }
  public static getActivityAreas(): AxiosPromise {
    return http.get('/dicts/activityareas')
  }
  public static getStudentDirections(): AxiosPromise {
    return http.get('/dicts/studentdirection')
  }

  public static createActivityAreas(title: string): AxiosPromise {
    return http.post('/dicts/activityareas/', { title })
  }
  public static createProjectTypes(title: string): AxiosPromise {
    return http.post('/dicts/projectypes/', { title })
  }
  public static createEventTypes(title: string): AxiosPromise {
    return http.post('/dicts/eventypes/', { title })
  }
  public static createStudentDirection(title: string): AxiosPromise {
    return http.post('/dicts/studentdirection/', { title })
  }

  public static updateEventTypes(id: number, title: string): AxiosPromise {
    return http.put(`/dicts/eventypes/${id}`, { title })
  }
  public static updateProjectTypes(id: number, title: string): AxiosPromise {
    return http.put(`/dicts/projectypes/${id}`, { title })
  }
  public static updateActivityAreas(id: number, title: string): AxiosPromise {
    return http.put(`/dicts/activityareas/${id}`, { title })
  }
  public static updateStudentDirection(
    id: number,
    title: string
  ): AxiosPromise {
    return http.put(`/dicts/studentdirection/${id}`, { title })
  }

  public static deleteProjectTypes(id: number): AxiosPromise {
    return http.delete(`/dicts/projectypes/${id}`, {
      validateStatus: () => true
    })
  }
  public static deleteEventTypes(id: number): AxiosPromise {
    return http.delete(`/dicts/eventypes/${id}`, {
      validateStatus: () => true
    })
  }
  public static deleteActivityAreas(id: number): AxiosPromise {
    return http.delete(`/dicts/activityareas/${id}`, {
      validateStatus: () => true
    })
  }
  public static deleteStudentDirection(id: number): AxiosPromise {
    return http.delete(`/dicts/studentdirection/${id}`, {
      validateStatus: () => true
    })
  }

  public static getSpecialitiesOrderlyOnlyPlans(): AxiosPromise {
    const params = new URLSearchParams()
    params.append('scopes', 'orderByCipher')
    params.append('scopes', 'withPlans')
    return http.get('/dicts/specialties', { params })
  }

  public static getSpecialities(): AxiosPromise {
    const params = new URLSearchParams()
    params.append('isProfile', 'true')
    return http.get('/dicts/specialties', { params })
  }

  public static getAllArrNameSubjects(
    idSpecialitiesArr: number[]
  ): AxiosPromise {
    const params = new URLSearchParams()
    params.append('scopes', 'simple')
    for (const item of idSpecialitiesArr) {
      params.append('specialtyId', item.toString())
    }
    return http.get(`/dicts/planstrings`, { params })
  }

  public static getStudyLevels(): AxiosPromise {
    return http.get('/dicts/studylevels')
  }

  public static getStudyForms(): AxiosPromise {
    return http.get('/dicts/studyforms')
  }

  public static getUniversities(): AxiosPromise {
    return http.get('/dicts/universities')
  }
}
