import _ from 'lodash'
import {
  projectStudentStatusBadges,
  statusTranslate
} from '@/config/projects/status'

const style = `
  margin-left: 3px;
`

export default class ProjectStudentStatusRenderer {
  private eGui: any

  init(params: any) {
    this.eGui = document.createElement('span')
    if (_.isNil(params.value)) {
      return
    }
    const text = `
        <div
          class="Badge_${params.value}"
          style="${style}" title="${statusTranslate[params.value]}">
          ${projectStudentStatusBadges[params.value]}
        </div>
        `
    this.eGui.innerHTML = text
  }

  getGui() {
    return this.eGui
  }
}
