import { ifAuthenticated } from '@/router/before'
import { baseURL } from '@/api/httpAxios'

export const devRouteNames = {
  ServerAPIRoute: 'ServerAPIRoute',
  ChangeRoleRoute: 'ChangeRoleRoute',
  ChangeUserRoute: 'ChangeUserRoute',
  FakeAuthRoute: 'FakeAuthRoute'
}
const rn = devRouteNames

export const devRouteURLs = {
  [rn.ServerAPIRoute]: '/dev/api-redirect',
  [rn.ChangeRoleRoute]: '/dev/change-roles',
  [rn.ChangeUserRoute]: '/dev/change-user',
  [rn.FakeAuthRoute]: '/dev/fake-auth'
}

export const devPages = [
  {
    path: devRouteURLs[rn.ServerAPIRoute],
    name: rn.ServerAPIRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'code'],
        isDisplay: true
      },
      navbar: {
        group: 'Development',
        displayName: 'Swagger API'
      },
      breadcrumbs: null
    },
    beforeEnter: () => {
      window.open(`${baseURL}/docs/`, '_blank')
    }
  },
  {
    path: devRouteURLs[rn.ChangeRoleRoute],
    name: rn.ChangeRoleRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'sync'],
        isDisplay: true
      },
      navbar: {
        group: 'Development',
        displayName: 'Смена ролей и прав'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ChangeRoleRoute" */ '@/views/dev/ChangeRoleRoute.vue'
      )
  },
  {
    path: devRouteURLs[rn.ChangeUserRoute],
    name: rn.ChangeUserRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'user-circle'],
        isDisplay: true
      },
      navbar: {
        group: 'Development',
        displayName: 'Смена пользователя'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ChangeUserRoute" */ '@/views/dev/ChangeUserRoute.vue'
      )
  },
  {
    path: devRouteURLs[rn.FakeAuthRoute],
    name: rn.FakeAuthRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'user-secret'],
        isDisplay: true
      },
      navbar: {
        group: 'Development',
        displayName: 'Просмотр за другого пользователя'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "FakeAuthRoute" */ '@/views/dev/FakeAuthRoute.vue'
      )
  }
]
