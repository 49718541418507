import { ifAuthenticated } from '@/router/before'

export const resumeRouteNames = {
  ProfileRoute: 'ProfileRoute',
  ResumeCardsRoute: 'ResumeCardsRoute',
  ResumeViewRoute: 'ResumeViewRoute'
}
const rn = resumeRouteNames

export const resumeRouteURLs = {
  [rn.ProfileRoute]: '/profile',
  [rn.ResumeCardsRoute]: '/resume/',
  [rn.ResumeViewRoute]: '/resume/:userId'
}

export const resumePages = [
  {
    path: resumeRouteURLs[rn.ProfileRoute],
    name: rn.ProfileRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'id-card'],
        isDisplay: true
      },
      navbar: {
        group: 'Главная',
        displayName: 'Профиль'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProfileRoute" */ '@/views/resume/ProfileRoute.vue'
      )
  },
  {
    path: resumeRouteURLs[rn.ResumeViewRoute],
    name: rn.ResumeViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Поиск сотрудников',
        displayName: 'Резюме студента'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ResumeViewRoute" */ '@/views/resume/ResumeViewRoute.vue'
      )
  },
  {
    path: resumeRouteURLs[rn.ResumeCardsRoute],
    name: rn.ResumeCardsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'id-card'],
        isDisplay: true
      },
      navbar: {
        group: 'Поиск сотрудников',
        displayName: 'Резюме студентов'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ResumeCardsRoute" */ '@/views/resume/ResumeCardsRoute.vue'
      )
  }
]
