























































import { Component, Vue } from 'vue-property-decorator'
import { userMappers } from '@/store/modules/user'
import WarningModal from '@/components/ui/WarningModal.vue'
import PartnersAPI from '@/api/partners'
import { clientBaseWithSlash } from '@/config/urls'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters([
      'currentUser',
      'partnerPosition',
      'isMultiPartner',
      'allPartnerCompanies'
    ])
  }
})

@Component({ components: { WarningModal } })
export default class Organisation extends Mappers {
  private switchOrganisation: any = null

  $refs!: {
    switchOrgModal: WarningModal
  }

  private getInitials() {
    return this.currentUser && this.currentUser.initials
      ? this.currentUser.initials
      : 'Неизвестный'
  }

  private get switchOrganisationList() {
    return this.allPartnerCompanies.filter(
      c => c.id !== this.partnerPosition?.company?.id
    )
  }

  private async onSwitchOrganisation(organisation: any) {
    this.switchOrganisation = organisation
    await this.$nextTick()

    this.$refs.switchOrgModal.show(async () => {
      await PartnersAPI.changeCompany(this.switchOrganisation.id)
      this.switchOrganisation = null
      window.location.reload()
      window.location.href = clientBaseWithSlash
    })
  }

  private getCompanyTitle() {
    const company = this.partnerPosition
    return company?.company?.title ?? ''
  }
}
