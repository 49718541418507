








import { Vue, Component } from 'vue-property-decorator'

import { loggerMapper } from '@/store/modules/logger'
import { userMappers } from '@/store/modules/user'
import { notificationsMapper } from '@/store/modules/notifications'
import { isDev, vueDevToolsURL } from '@/config/urls'
import UserAPI from '@/api/user'
import { routes } from '@/router'
import { toastMapper } from '@/store/modules/toast'
import _ from 'lodash'
import { Route } from 'vue-router/types/router'

const Mappers = Vue.extend({
  computed: {
    ...loggerMapper.mapState(['logs']),
    ...userMappers.mapState(['user'])
  },
  methods: {
    ...notificationsMapper.mapActions(['fetchBellNotifications']),
    ...loggerMapper.mapMutations(['addLog']),

    ...loggerMapper.mapActions(['sendLogs']),
    ...toastMapper.mapActions(['pushToast'])
  }
})
@Component
export default class App extends Mappers {
  private interval?: any

  private async created() {
    window.onbeforeunload = null
    window.onbeforeunload = () => {
      this.sendLogs()
    }
    this.interval = setInterval(() => {
      this.sendLogs()
    }, 30000)
    window.addEventListener(
      'unhandledrejection',
      (e: PromiseRejectionEvent) => {
        if (this.user) {
          this.addLog({
            userId: this.user.id,
            severity: 'error',
            message: 'ERROR',
            page: this.$route.path,
            error: {
              type: e.type,
              message: e.reason.message,
              stack: e.reason.stack
            },
            timestamp: Date.now()
          })
          this.sendLogs()
        }
      }
    )
    if (this.user) {
      this.addLog({
        userId: this.user.id,
        severity: 'info',
        message: 'LOG IN',
        page: this.$route.path,
        timestamp: Date.now()
      })
      this.fetchBellNotifications()
      setInterval(this.fetchBellNotifications, 5 * 60 * 1000) // 5min
      this.sendLogs()
    }

    if (isDev) {
      const vueDevTools = document.createElement('script')
      vueDevTools.setAttribute('src', vueDevToolsURL)
      document.head.appendChild(vueDevTools)

      const routesOrdered = _.sortBy(
        (routes[0] as any).children.map((r: Route) => ({
          path: r.path,
          name: r.name
        })),
        'name'
      )
      const response = await UserAPI.validateClientRoutes(routesOrdered)
      const diff = response.data?.status ?? []
      if (diff.length > 0) {
        this.pushToast({
          error: true,
          title: 'Developer',
          message: `URL клиента и сервера не совпадают: ${JSON.stringify(
            diff
          )}`,
          time: 9999
        })
      }
    }
  }

  private beforeDestroy() {
    clearInterval(this.interval)
  }
}
