import { ifAuthenticated } from '@/router/before'

export const adminRouteNames = {
  ActivityRoute: 'ActivityRoute',
  ServerLogsRoute: 'ServerLogsRoute',
  SessionsRoute: 'SessionsRoute',
  HistoryRoute: 'HistoryRoute',
  BuildInfoRoute: 'BuildInfoRoute',
  UserPermissionsRoute: 'UserPermissionsRoute'
}
const rn = adminRouteNames

export const adminRouteURLs = {
  [rn.ActivityRoute]: '/admin/activity',
  [rn.ServerLogsRoute]: '/admin/logs',
  [rn.SessionsRoute]: '/admin/sessions',
  [rn.HistoryRoute]: '/admin/history',
  [rn.BuildInfoRoute]: '/admin/build',
  [rn.UserPermissionsRoute]: '/admin/users'
}

export const adminPages = [
  {
    path: adminRouteURLs[rn.ActivityRoute],
    name: rn.ActivityRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'tasks'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'Журнал активности'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ActivityRoute" */ '@/views/admin/ActivityRoute.vue'
      )
  },
  {
    path: adminRouteURLs[rn.ServerLogsRoute],
    name: rn.ServerLogsRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'satellite-dish'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'Логи сервера'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ServerLogsRoute" */ '@/views/admin/ServerLogsRoute.vue'
      )
  },
  {
    path: adminRouteURLs[rn.SessionsRoute],
    name: rn.SessionsRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'binoculars'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'Активные сессии'
      },
      breadcrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "SessionsRoute" */ '@/views/admin/SessionsRoute.vue'
      )
  },
  {
    path: adminRouteURLs[rn.HistoryRoute],
    name: rn.HistoryRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'history'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'История изменений'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "HistoryRoute" */ '@/views/admin/HistoryRoute.vue'
      )
  },
  {
    path: adminRouteURLs[rn.BuildInfoRoute],
    name: rn.BuildInfoRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'cog'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'Обслуживание системы'
      },
      breadcrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "BuildInfoRoute" */ '@/views/admin/BuildInfoRoute.vue'
      )
  },
  {
    path: adminRouteURLs[rn.UserPermissionsRoute],
    name: rn.UserPermissionsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'user'],
        isDisplay: true
      },
      navbar: {
        group: 'Администрирование',
        displayName: 'Назначение прав и ролей'
      },
      breadcrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "UserPermissionsRoute" */ '@/views/admin/UserPermissionsRoute.vue'
      )
  }
]
