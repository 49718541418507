import {
  Actions,
  createMapper,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module'

export interface StaffPartner {
  surname: string
  name: string
  midname?: string
  email: string
  phone?: string
  position: string
  id?: number
}

class PrManagerState {
  idPartner = 1
  optionsEmployees: any = {}
  listOrganization: any = []
}

class PrManagerGetters extends Getters<PrManagerState> {}

class PrManagerMutations extends Mutations<PrManagerState> {
  setOptionsEmployees(obj: any) {
    this.state.optionsEmployees = obj
  }
}

class PrManagerActions extends Actions<
  PrManagerState,
  PrManagerGetters,
  PrManagerMutations,
  PrManagerActions
> {}

export const prManager = new Module({
  state: PrManagerState,
  getters: PrManagerGetters,
  mutations: PrManagerMutations,
  actions: PrManagerActions
})

export const prManagerMapper = createMapper(prManager)
