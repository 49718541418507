import { Getters, Mutations, Module, createMapper } from 'vuex-smart-module'

class DepHeadPartnerState {
  tableData: any[] = []
}

class DepHeadPartnerGetters extends Getters<DepHeadPartnerState> {
  get tableData() {
    return this.state.tableData
  }
}

class DepHeadPartnerMutations extends Mutations<DepHeadPartnerState> {
  setTableData(data: any[]) {
    this.state.tableData = data
  }
}

export const depHeadPartner = new Module({
  state: DepHeadPartnerState,
  getters: DepHeadPartnerGetters,
  mutations: DepHeadPartnerMutations
})

export const depHeadPartnerMapper = createMapper(depHeadPartner)
