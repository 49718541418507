export default {
  colors: {
    'blue-1': '#1e3799',
    'yellow-1': '#e58e26',
    'red-1': '#b71540',
    'green-1': '#079992',
    'blue-2': '#6a89cc'
  },
  monthTranslations: {
    JAN: 'Янв',
    FEB: 'Фев',
    MAR: 'Мар',
    APR: 'Апр',
    MAY: 'Май',
    JUN: 'Июн',
    JUL: 'Июл',
    AUG: 'Авг',
    SEP: 'Сен',
    OCT: 'Окт',
    NOV: 'Ноя',
    DEC: 'Дек'
  },
  monthOrder: {
    0: 'JAN',
    1: 'FEB',
    2: 'MAR',
    3: 'APR',
    4: 'MAY',
    5: 'JUN',
    6: 'JUL',
    7: 'AUG',
    8: 'SEP',
    9: 'OCT',
    10: 'NOV',
    11: 'DEC'
  }
}
