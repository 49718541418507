import { http } from '@/api/httpAxios'

import {
  LIMIT_PAGINATION_BELL,
  notificationTypes
} from '@/config/notificationTypes'

export default class NotificationsAPI {
  public static getUserNotifications(limit: number = LIMIT_PAGINATION_BELL) {
    return http.get(`/notifications/latest?limit=${limit}`)
  }

  public static getNotificationWithPagination(
    limit: number,
    offset: number,
    type?: notificationTypes
  ) {
    const params = new URLSearchParams()
    params.append('limit', limit.toString())
    params.append('offset', offset.toString())
    if (type) {
      params.append('type', type.toString())
    }
    return http.get(`/notifications?${params.toString()}`)
  }

  public static changeReadStatus(notificationId: number, status: boolean) {
    return http.put(`/notifications/${notificationId}?isRead=${status}`)
  }

  public static markNotificationAsReadByType(type: notificationTypes) {
    return http.post(`/notifications/read?type=${type}`)
  }
  public static markNotificationAsReadAll() {
    return http.post(`/notifications/read`)
  }
  public static markNotificationAsRead(notificationId: number) {
    return http.put(`/notifications/${notificationId}?isRead=true`)
  }
}
