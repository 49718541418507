import _ from 'lodash'
import { User } from '@/api/user'
import ChartsConfig from '@/config/charts/charts'

export function getUserText(user: User | null) {
  if (_.isNil(user)) {
    return ''
  }
  let str = user.initials
  if (!str) {
    str = `${user.surname} ${user.name[0]}.${
      user.midname ? user.midname[0] + '.' : ''
    }`
  }
  if (user.userDepartments?.length > 0) {
    str += ` (${user.userDepartments
      .map(ud => {
        return `${ud.workDepartment.shortTitle ??
          ud.workDepartment.title} - ${ud.workPosition.shortTitle ??
          ud.workPosition.title}`
      })
      .join(', ')})`
  } else if (user.aisDepartments?.length > 0) {
    str += ` (${user.aisDepartments?.map(d => d.shortTitle).join(', ')})`
  }
  if (user.actualUser) {
    str += ` [${getUserText(user.actualUser)}]`
  }
  return str
}

export function prioritizeLkDepartments(departments: any[]) {
  departments = _.sortBy(departments, dep => {
    const title = dep.workDepartment.title.toLowerCase()
    if (title.includes('ректорат')) {
      return -1
    }
    if (title.includes('факультет') || title.includes('деканат')) {
      return 0
    }
    if (title.includes('кафедра')) {
      return 1
    }
    return 2
  })
  return departments
}

export function getWorkPositionTitle(position: string) {
  position = position.toLowerCase()
  position = position.replace('доктор наук', '')
  position = position.replace('кандидат наук', '')
  position = position.trim()
  return position
}

export function prioritizePositions(departments: any[]) {
  return _.sortBy(departments, dep => {
    const position = getWorkPositionTitle(dep.workPosition.title)
    if (position.includes('заведующий кафедрой')) {
      return 0
    }
    if (position.includes('доцент')) {
      return 1
    }
    if (position.includes('старший преподаватель')) {
      return 2
    }
    if (position.includes('ассистент')) {
      return 3
    }
    return 4
  })
}

export function formatLkUserDepartments(departments: any[]) {
  departments = prioritizeLkDepartments(departments)
  const departmentGroups = _.groupBy(departments, d => d.workDepartment.title)
  const result = []
  for (const department of departments) {
    if (departmentGroups[department.workDepartment.title]) {
      const positions = prioritizePositions(
        departmentGroups[department.workDepartment.title]
      )
      result.push(
        `${department.workDepartment.shortTitle} (${positions
          .map(p => getWorkPositionTitle(p.workPosition.title))
          .join(', ')})`
      )
      delete departmentGroups[department.workDepartment.title]
    }
  }
  return result.join(', ')
}

export function parseTimestampForChat(datetime: string) {
  const dt = new Date(datetime)
  return {
    year: dt.getFullYear(),
    month: dt.getMonth() + 1,
    day: dt.getDate(),
    hour: dt.getHours(),
    minute: dt.getMinutes(),
    second: dt.getSeconds(),
    millisecond: dt.getMilliseconds()
  }
}

export function generateListHTMLRights(rights?: string[]) {
  let result = ''
  if (rights && rights.length) {
    result += '<ul>'
    for (const right of rights) {
      result += `<li>${right}</li>`
    }
    result += '</ul>'
    return result
  }
  return '-'
}

export function isNumeric(value: string) {
  return /^-{0,1}\d+$/.test(value)
}

export function arrayRotate(arr: any[], count: number) {
  return arr.concat(arr.splice(0, count))
}

export function translateMonth(array: string[]) {
  return array.map(month => (ChartsConfig.monthTranslations as any)[month])
}

export function getActualUserGroup(gridValue: any) {
  return gridValue && gridValue.length > 0
    ? (_.maxBy(gridValue, 'group.id') as any)?.group?.name
    : ''
}

export function getActualUserDepartmentGroup(
  gridValue: any,
  key = 'shortTitle'
) {
  return gridValue && gridValue.length > 0
    ? (_.maxBy(gridValue, 'group.id') as any)?.group?.plan?.department[key] ??
        '-'
    : ''
}
