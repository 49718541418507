import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import cookies from 'browser-cookies'
import store from './store'
import i18n from './i18n'
import './class-component-hooks'

/* Bootstrap */
import { BootstrapVue } from 'bootstrap-vue'
/* Notifications bell */
import NotificationBell from 'vue-notification-bell'
/* Fonts Awesome */
import { dom, library } from '@fortawesome/fontawesome-svg-core'
// TODO extract library for production
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* Form Generator */
import VueFormGenerator from 'vue-form-generator'
import VueExpandableImage from 'vue-expandable-image'
/* Custom Field */
import AgGridField from '@/components/fields/AgGrid.vue'
import HeaderSeparator from '@/components/fields/HeaderSeparator.vue'

/* Notification */
import Notifications from 'vue-notification'
/* vxe-table */
import 'xe-utils'
import VXETable from 'vxe-table'

/* vue-cropper */
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

Vue.use(BootstrapVue)

Vue.use(NotificationBell)

library.add(fas, far, fab)
dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)

// import 'vue-form-generator/dist/vfg.css'
Vue.use(VueFormGenerator)

/* Draggable */
// import draggable from 'vuedraggable'
// Vue.use(draggable)
Vue.use(VueExpandableImage)

/* Sentry */
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.VUE_APP_SENTRY_ENV !== 'dev') {
  Sentry.init({
    Vue,
    dsn: 'https://e9060b9c11bf463b9801c90892769239@digital-sentry.ddns.net/6',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'digital.etu.ru', /^\//]
      })
    ],
    ignoreErrors: [
      'ChunkLoadError',
      '{no status}',
      'Navigation cancelled from',
      'Cannot redefine property: googletag',
      'Redirected when going from',
      'Avoided redundant navigation',
      '[IGNORE]'
    ],
    normalizeDepth: 4,
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.VUE_APP_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACE_RATE)
  })
}

Vue.use(Notifications)

Vue.component('vue-cropper', VueCropper)

import { Components, VueFormGeneratorFields } from '@orpk/orpk-vue-ui'
import UserPicker from '@/components/fields/UserPicker.vue'

Vue.component('MainCard', Components.MainCard)
Vue.component('InfoModal', Components.InfoModal)
Vue.component('WarningModal', Components.WarningModal)
Vue.component('ClassicCKEditor', Components.ClassicCKEditor)
Vue.component('DevServerModal', Components.DevServerModal)
Vue.component('LoadingBottomCard', Components.LoadingBottomCard)
Vue.component('NotificationChangelog', Components.NotificationChangelog)
Vue.component('Changelog', Components.Changelog)

Vue.component('fieldCKEditor', VueFormGeneratorFields.CKEditor5Field)
Vue.component('fieldAgGrid', AgGridField)
Vue.component('fieldBootstrap', VueFormGeneratorFields.BootstrapInput)
Vue.component(
  'fieldBootstrapCheckbox',
  VueFormGeneratorFields.BootstrapCheckbox
)
Vue.component(
  'fieldBootstrapCheckboxGroup',
  VueFormGeneratorFields.BootstrapCheckboxGroup
)
Vue.component(
  'fieldBootstrapDatepicker',
  VueFormGeneratorFields.BootstrapDatepicker
)
Vue.component('fieldDatepicker', VueFormGeneratorFields.DatepickerField)
Vue.component(
  'fieldBootstrapRadioGroup',
  VueFormGeneratorFields.BootstrapRadioGroup
)
Vue.component('fieldBootstrapSelect', VueFormGeneratorFields.BootstrapSelect)
Vue.component(
  'fieldBootstrapTextArea',
  VueFormGeneratorFields.BootstrapTextArea
)
Vue.component('fieldMultiselect', VueFormGeneratorFields.MultiselectField)
Vue.component('fieldHeaderSeparator', HeaderSeparator)
Vue.component('fieldUserPicker', UserPicker)

VXETable.setup({
  i18n: key => i18n.t(key),
  translate(key: string) {
    if (key && key.indexOf('app.') > -1) {
      return i18n.t(key)
    }
    return key
  }
})
Vue.use(VXETable)

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.silent = true
}

if (cookies.get('auth-redirect-uri')) {
  const link = cookies.get('auth-redirect-uri')!
  cookies.erase('auth-redirect-uri')
  window.location.href = link
}

store.dispatch('user/fetchCurrentUser').then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
