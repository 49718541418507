







import { Component, Mixins } from 'vue-property-decorator'
import { abstractField } from 'vue-form-generator'
import { GenField } from 'vue-form-generator'

@Component
export default class HeaderSeparator extends Mixins(abstractField) {
  schema!: GenField
  hasHR = false
}
