




























import { Component, Vue } from 'vue-property-decorator'

import { navBarTitleMapper } from '@/store/modules/navbar'
import { userMappers } from '@/store/modules/user'
import NotificationBellComponent from '@/components/pages/home/components/navbar/components/NotificationBellComponent.vue'
import Logout from '@/components/pages/home/components/navbar/components/Logout.vue'

import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import Login from '@/components/pages/home/components/navbar/components/Login.vue'
import { clientBaseWithSlash } from '@/config/urls'
import { commonRouteURLs, commonRouteNames } from '@/router/pages/common'
import ChangeLogAPI from '@/components/routes/common/changelog/helpers/requests'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile']),
    ...userMappers.mapGetters(['isAuthenticated']),
    ...navBarTitleMapper.mapGetters(['buildTitle'])
  }
})

@Component({
  components: { NotificationBellComponent, Login, Logout }
})
export default class Navbar extends Mappers {
  private getHasUnread_ = ChangeLogAPI.getHasUnread

  private get getHome() {
    return this.buildTitle(this.isMobile) === 'К вакансиям'
      ? clientBaseWithSlash + 'vacancies'
      : clientBaseWithSlash
  }

  private onRedirectChangelog() {
    this.$router.push(commonRouteURLs[commonRouteNames.ChangelogRoute])
  }
}
