


























import io, { Socket } from 'socket.io-client'
import { BModal } from 'bootstrap-vue'
import { Vue, Component, Watch } from 'vue-property-decorator'

import { socketURL, socketPath } from '@/api/httpAxios'

import ReloadModal from '@/components/pages/home/components/ReloadModal.vue'
import MaintenanceModal from '@/components/pages/home/components/MaintenanceModal.vue'
import Navbar from '@/components/pages/home/components/navbar/index.vue'
import Sidebar from '@/components/pages/home/components/sidebar/Sidebar.vue'
import Config from '@/config/config'

import { userMappers } from '@/store/modules/user'
import { loadingMapper } from '@/store/modules/loading'
import { toastMapper } from '@/store/modules/toast'
import { notificationsMapper } from '@/store/modules/notifications'
import { MaintenanceInfo } from 'interfaces/notifications'
import MobileWidthMixin from '@/mixins/mobile'
import Breadcrumbs from '@/components/pages/home/components/Breadcrumbs.vue'
import Toaster from '@/components/pages/home/components/Toaster.vue'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import FloatingButton from '@/components/pages/home/components/float-button/index.vue'
import { productionURL, devProductionURL } from '@/config/urls'
import CookiesUsage from '@orpk/orpk-vue-ui/src/components/CookiesUsage.vue'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['isAuthenticated', 'isPartner', 'isPRManager']),
    ...userMappers.mapState(['clientVersion', 'isBadLogin']),
    ...loadingMapper.mapGetters(['showProgress']),
    ...loadingMapper.mapState(['processed', 'enqueued'])
  },
  methods: {
    ...notificationsMapper.mapMutations(['setMaintenanceInfo']),
    ...toastMapper.mapActions(['pushToast']),
    ...userMappers.mapMutations(['setUser'])
  }
})

@Component({
  components: {
    Navbar,
    Toaster,
    Sidebar,
    ReloadModal,
    MaintenanceModal,
    Breadcrumbs,
    FloatingButton,
    CookiesUsage
  },
  mixins: [MobileWidthMixin, RouterEventWrapper]
})
export default class Home extends Mappers {
  routerPush!: Function

  private productionURL_ = productionURL
  private devProductionURL_ = devProductionURL

  $refs!: { modal: BModal; reloadModal: ReloadModal }
  private socket!: typeof Socket

  mounted() {
    if (this.clientVersion) {
      const clientVersion = window.localStorage.getItem('client-version')
      if (clientVersion !== this.clientVersion.commit) {
        window.localStorage.setItem('client-version', this.clientVersion.commit)
        window.location.reload(true)
      }
    }

    if (!this.isAuthenticated) {
      return
    }
    this.socket = io(socketURL, { path: socketPath })

    this.socket.on('connect', () => {
      this.socket.emit('get-maintenance')
    })

    this.socket.on('maintenance-info', (data: MaintenanceInfo) => {
      this.setMaintenanceInfo(data)
    })
  }

  @Watch('isBadLogin')
  onBadLogin(isBadLogin: boolean) {
    if (this.isAuthenticated && isBadLogin) {
      this.pushToast({
        error: true,
        message: 'Для выполнения действия необходимо войти в систему'
      })
      this.setUser(null)
      this.routerPush(Config.routing.authPage.path)
    }
  }

  onReload() {
    location.reload(true)
  }
}
