

































import { Component, Vue } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'

import { notificationsMapper } from '@/store/modules/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapState(['reloadNotification'])
  }
})

@Component
export default class ReloadModal extends Mappers {
  $refs!: { modal: BModal }

  private secondsWait = 300
  private secondsDelay = 60 * 60 * 1000
  private value = 0
  private interval?: ReturnType<typeof setInterval>

  get notification() {
    if (this.reloadNotification) {
      return this.reloadNotification
    }
    return `Необходима перезагрузка страницы.`
  }

  onOk() {
    location.reload(true)
  }

  onCancel() {
    setTimeout(() => {
      this.show()
    }, this.secondsDelay)

    clearInterval(this.interval!)
  }

  public show() {
    this.$refs.modal.show()
    this.value = this.secondsWait
    this.interval = setInterval(() => {
      if (this.value > 0) {
        this.value--
      } else {
        clearInterval(this.interval!)
        location.reload(true)
      }
    }, 1000)
  }
}
