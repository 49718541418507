import {
  Getters,
  Mutations,
  Actions,
  Module,
  createMapper
} from 'vuex-smart-module'
import { Vue } from 'vue-property-decorator'
import _ from 'lodash'
import DictsAPI from '@/api/dicts'
import Mutex from 'ts-mutex'

class DictsState {
  specialities: any[] = []
  faculties: any[] = []
  departments: any[] = []
  depHeads: { [k: number]: any } = {}
  plans: any[] = []
  universities: {
    code: string
    name: string
    universities: {
      id: string
      name: string
    }[]
  }[] = []

  allNameSubjectsSomeSpec: any[] = []

  specialitiesOrderlyOnlyPlans: any[] = []

  departmentLock = new Mutex()
  specialitiesOrderlyLock = new Mutex()

  projectTypes: any[] = []
  projectTypesLock = new Mutex()

  eventTypes: any[] = []
  eventTypesLock = new Mutex()

  studyLevel: any[] = []
  studyLevelLock = new Mutex()

  studyForm: any[] = []
  studyFormLock = new Mutex()

  activityAreas: any[] = []
  activityAreasLock = new Mutex()
}

class DictsGetters extends Getters<DictsState> {}

class DictsMutations extends Mutations<DictsState> {
  setDepartments(departments: any[]) {
    this.state.departments = _.sortBy(
      departments.map(d => _.omit(d, ['lkDepartment'])),
      'title'
    )
    Vue.set(this.state, 'depHeads', {})
    for (const department of departments) {
      const leader = department.lkDepartment?.positions?.[0]
      if (leader) {
        Vue.set(this.state.depHeads, department.id, leader)
      }
    }
  }

  setFaculties(facs: []) {
    this.state.faculties = facs
  }

  setUniversities(universities: any[]) {
    this.state.universities = universities
  }

  setNameSubjectsSomeSpec(allNameSubjectsSomeSpec: any[]) {
    this.state.allNameSubjectsSomeSpec = allNameSubjectsSomeSpec
  }

  setSpecialities(specialities: []) {
    this.state.specialities = specialities
  }

  setSpecialitiesOrderlyOnlyPlans(specialities: []) {
    this.state.specialitiesOrderlyOnlyPlans = specialities
  }

  setProjectTypes(types: []) {
    this.state.projectTypes = types
  }

  setEventTypes(types: []) {
    this.state.eventTypes = types
  }

  setStudyLevel(data: []) {
    this.state.studyLevel = data
  }
  setStudyForm(data: []) {
    this.state.studyForm = data
  }
  setActivityAreas(data: []) {
    this.state.activityAreas = data
  }
}

class DictsActions extends Actions<
  DictsState,
  DictsGetters,
  DictsMutations,
  DictsActions
> {
  async fetchDepartments() {
    await this.state.departmentLock.use(async () => {
      try {
        if (_.isEmpty(this.state.departments)) {
          const response = await DictsAPI.getDepartments()
          this.mutations.setDepartments(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchFaculties() {
    try {
      if (_.isEmpty(this.state.faculties)) {
        const response = await DictsAPI.getFaculties()
        this.mutations.setFaculties(response.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  async fetchSpecialities() {
    try {
      if (_.isEmpty(this.state.specialities)) {
        const response = await DictsAPI.getSpecialities()
        this.mutations.setSpecialities(response.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  async fetchSpecialitiesOrderlyOnlyPlans() {
    await this.state.specialitiesOrderlyLock.use(async () => {
      try {
        if (_.isEmpty(this.state.specialitiesOrderlyOnlyPlans)) {
          const response = await DictsAPI.getSpecialitiesOrderlyOnlyPlans()
          this.mutations.setSpecialitiesOrderlyOnlyPlans(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchAllNameSubjectsSomeSpec(arrIdSpecialities: number[]) {
    try {
      const response = await DictsAPI.getAllArrNameSubjects(arrIdSpecialities)
      this.mutations.setNameSubjectsSomeSpec(response.data)
    } catch (err) {
      console.error(err)
    }
  }

  async fetchProjectTypes() {
    await this.state.projectTypesLock.use(async () => {
      try {
        if (_.isEmpty(this.state.projectTypes)) {
          const response = await DictsAPI.getProjectTypes()
          this.mutations.setProjectTypes(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchEventTypes() {
    await this.state.eventTypesLock.use(async () => {
      try {
        if (_.isEmpty(this.state.eventTypes)) {
          const response = await DictsAPI.getEventTypes()
          this.mutations.setEventTypes(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }
  async fetchStudyLevel() {
    await this.state.studyLevelLock.use(async () => {
      try {
        if (_.isEmpty(this.state.studyLevel)) {
          const response = await DictsAPI.getStudyLevels()
          this.mutations.setStudyLevel(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchStudyForm() {
    await this.state.studyFormLock.use(async () => {
      try {
        if (_.isEmpty(this.state.studyForm)) {
          const response = await DictsAPI.getStudyForms()
          this.mutations.setStudyForm(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchActivityAreas() {
    await this.state.activityAreasLock.use(async () => {
      try {
        if (_.isEmpty(this.state.activityAreas)) {
          const response = await DictsAPI.getActivityAreas()
          this.mutations.setActivityAreas(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  async fetchUniversities() {
    try {
      if (_.isEmpty(this.state.universities)) {
        const response = await DictsAPI.getUniversities()
        response.data = _.sortBy(response.data, 'name')
        for (const datum of response.data) {
          datum.universities = _.sortBy(datum.universities, 'name')
        }
        this.mutations.setUniversities(response.data)
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const dicts = new Module({
  state: DictsState,
  getters: DictsGetters,
  mutations: DictsMutations,
  actions: DictsActions
})

export const dictsMapper = createMapper(dicts)
