import { ifAuthenticated } from '@/router/before'

export const practiceRouteNames = {
  PracticeListRoute: 'PracticeListRoute',
  PracticeViewRoute: 'PracticeViewRoute',
  PracticeTopicsRoute: 'PracticeTopicsRoute',
  PracticeDepHeadManagementRoute: 'PracticeDepHeadManagementRoute'
}
const rn = practiceRouteNames

export const practiceRouteURLs = {
  [rn.PracticeListRoute]: '/partner/:partnerId/practices',
  [rn.PracticeViewRoute]: '/partner/:partnerId/practices/:practiceId',
  [rn.PracticeTopicsRoute]:
    '/partner/:partnerId/practices/:practiceId/theme/:themeId',
  [rn.PracticeDepHeadManagementRoute]: '/practices/dephead-management'
}

export const practicePages = [
  {
    path: practiceRouteURLs[rn.PracticeListRoute],
    name: rn.PracticeListRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'palette'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Практики',
        displayName: 'Список практик организации'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Практики'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PracticeListRoute" */ '@/views/practices/PracticeListRoute.vue'
      )
  },
  {
    path: practiceRouteURLs[rn.PracticeViewRoute],
    name: rn.PracticeViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Практики',
        displayName: 'Темы практики'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PracticeViewRoute" */ '@/views/practices/PracticeViewRoute.vue'
      )
  },
  {
    path: practiceRouteURLs[rn.PracticeTopicsRoute],
    name: rn.PracticeTopicsRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Практики',
        displayName: 'Тема практики'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PracticeTopicsRoute" */ '@/views/practices/PracticeTopicsRoute.vue'
      )
  },
  {
    path: practiceRouteURLs[rn.PracticeDepHeadManagementRoute],
    name: rn.PracticeDepHeadManagementRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'graduation-cap'],
        isDisplay: true
      },
      navbar: {
        group: 'Практики',
        displayName: 'Список практик'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PracticeDepHeadManagementRoute" */ '@/views/practices/PracticeDepHeadManagementRoute.vue'
      )
  }
]
