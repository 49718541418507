import { ifAuthenticated } from '@/router/before'
import Config from '@/config/config'
import { wikiURL } from '@/config/urls'

export const commonRouteNames = {
  NotificationsRoute: 'NotificationsRoute',
  HelpRoute: 'HelpRoute',
  ChangelogRoute: 'ChangelogRoute',
  ErrorRoute: 'ErrorRoute',
  MainPageRoute: 'MainPageRoute'
}
const rn = commonRouteNames

export const commonRouteURLs = {
  [rn.NotificationsRoute]: '/notifications',
  [rn.HelpRoute]: '/help-redirect',
  [rn.ChangelogRoute]: '/changelog',
  [rn.ErrorRoute]: '/error',
  [rn.MainPageRoute]: Config.routing.homePage.path
}

export const commonPages = [
  {
    path: commonRouteURLs[rn.NotificationsRoute],
    name: rn.NotificationsRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'envelope'],
        isDisplay: true
      },
      navbar: {
        group: 'Главная',
        displayName: 'Уведомления'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "NotificationsRoute" */ '@/views/common/NotificationsRoute.vue'
      )
  },
  {
    path: commonRouteURLs[rn.ChangelogRoute],
    name: rn.ChangelogRoute,
    meta: {
      sidebar: {
        icon: ['far', 'lightbulb'],
        isDisplay: true
      },
      navbar: {
        group: 'Главная',
        displayName: 'Обновления системы'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ChangelogRoute" */ '@/views/common/ChangelogRoute.vue'
      )
  },
  {
    path: commonRouteURLs[rn.HelpRoute],
    name: rn.HelpRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'question-circle'],
        isDisplay: true
      },
      navbar: {
        group: 'Главная',
        displayName: 'Справка'
      },
      breadcrumbs: null
    },
    beforeEnter: () => {
      window.location.href = wikiURL
    }
  },
  {
    path: commonRouteURLs[rn.ErrorRoute],
    name: rn.ErrorRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Ошибка 404',
        displayName: 'Страница не найдена'
      },
      breadcrumbs: null
    },
    component: () =>
      import(
        /* webpackChunkName: "ErrorRoute" */ '@/views/common/ErrorRoute.vue'
      )
  },
  {
    path: commonRouteURLs[rn.MainPageRoute],
    name: rn.MainPageRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'info-circle'],
        isDisplay: true
      },
      navbar: {
        group: 'Главная',
        displayName: Config.routing.homePage.name
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "MainPageRoute" */ '@/views/common/MainPageRoute.vue'
      )
  }
]
