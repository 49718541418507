import { http } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'
import {
  InterestsPartner,
  PartnerEventData,
  PartnerSendSpecialties,
  PartnerSendSubjects
} from '@/store/modules/partner'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { arrayBufferToBlob } from 'blob-util'
import { PartnersAdapter } from '@/api/adapters/partners'
import { permissionsPartner } from '@/api/permissions/CommonPartnerPermissions'
import { companyTypes } from '@/config/company/types'

function formatNewsData(news: any) {
  return {
    title: news.title,
    shortInfo: news.shortInfo,
    info: news.info
  }
}

async function formatAvatar(news: any) {
  const formData = new FormData()
  const avatarBlob = await (await fetch(news.avatar)).blob()
  const avatarFile = new File([avatarBlob], 'avatar.png', {
    type: 'image/png'
  })
  formData.append('avatar', avatarFile)

  const avatarCroppedBlob = await (await fetch(news.avatarCropped)).blob()
  const avatarCroppedFile = new File([avatarCroppedBlob], 'avatarCropped.png', {
    type: 'image/png'
  })
  formData.append('croppedAvatar', avatarCroppedFile)
  formData.append('croppedAvatarMeta', '0')
  return formData
}

export default class PartnersAPI {
  public static addPartner(
    title: string,
    permissions: permissionsPartner[]
  ): AxiosPromise {
    return http.post('/companies', { title, permissions })
  }
  public static deletePartner(partnerId: number): AxiosPromise {
    return http.delete(`/companies/${partnerId}`)
  }

  public static updatePartnerInfoByPRManager(
    partnerID: number,
    departments: number[],
    title: string,
    type: companyTypes
  ): AxiosPromise {
    return http.put(`/companies/${partnerID}/edit`, {
      departmentIds: departments,
      title,
      type
    })
  }

  public static getPartnersWithDeps(): AxiosPromise {
    const params = new URLSearchParams()
    params.append('scopes', 'withDepartments')
    params.append('scopes', 'minimumData')
    return http.get(`/companies?${params.toString()}`)
  }

  public static getPartner(partnerID: number): AxiosPromise {
    return http.get(`/companies/info/${partnerID}`)
  }
  public static getPartners(): AxiosPromise {
    const params = new URLSearchParams()
    params.append('scopes', 'withDepartments')
    return http.get(`/companies?${params.toString()}`)
  }
  public static getPartnersNonAuth(): AxiosPromise {
    return http.get(`/companies/non-auth`)
  }

  public static async getHistory(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ) {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get('/general/history', { params })
  }

  public static addSpecialtiesPartner(
    partnerId: number,
    data: PartnerSendSpecialties
  ): AxiosPromise {
    return http.post(`/companies/${partnerId}/specialties`, data)
  }

  public static deleteSpecialtiesPartner(
    partnerId: number,
    specialtyId: number
  ): AxiosPromise {
    return http.delete(`/companies/${partnerId}/specialties/${specialtyId}`)
  }

  public static addSubjectsPartner(
    partnerId: number,
    data: PartnerSendSubjects
  ): AxiosPromise {
    return http.post(`/companies/${partnerId}/subjects`, data)
  }

  public static getSubjectsPartner(partnerId: number): AxiosPromise {
    return http.get(`/companies/${partnerId}/subjects`)
  }

  public static deleteSubjectsPartner(
    partnerId: number,
    planStringId: number
  ): AxiosPromise {
    return http.delete(`/companies/${partnerId}/subjects/${planStringId}`)
  }

  public static changeSubjectsPartner(
    partnerId: number,
    planStringId: number,
    info: string
  ): AxiosPromise {
    return http.put(`/companies/${partnerId}/subjects/${planStringId}`, {
      info: info
    })
  }

  public static getInterestsPartner(partnerId: number): AxiosPromise {
    return http.get(`/interests/companies/${partnerId}`)
  }

  public static deleteInterestsPartner(
    partnerId: number,
    interestId: number
  ): AxiosPromise {
    return http.delete(`/interests/${interestId}/companies/${partnerId}`)
  }

  public static changeInterestsPartner(
    partnerId: number,
    data: InterestsPartner[]
  ): AxiosPromise {
    return http.put(`/interests/companies/${partnerId}`, data)
  }

  public static getPartnersNews(
    limit: number,
    offset: number,
    partnerId: number,
    publicated?: boolean,
    sort?: any,
    status?: string | null,
    title?: string | null
  ) {
    const params = createURLParams(limit, offset, sort)
    if (publicated) {
      params.append('publicated', String(publicated))
    }
    if (status) {
      params.append('status', status)
    }
    if (title) {
      params.append('title', title)
    }
    return http.get(`/companies/${partnerId}/news`, { params })
  }
  public static getOneNews(partnerId: number, newsId: number) {
    const params = new URLSearchParams()
    params.append('scopes', 'countLikes')
    return http.get(`/companies/${partnerId}/news/${newsId}`, { params })
  }
  public static async updateAvatar(
    partnerId: number,
    news: any,
    newsId: number
  ) {
    return http.put(
      `/companies/${partnerId}/news/${newsId}/avatar`,
      await formatAvatar(news),
      {
        validateStatus: () => true
      }
    )
  }
  public static async createNews(partnerId: number, news: any) {
    return http.post(`/companies/${partnerId}/news`, formatNewsData(news), {
      validateStatus: () => true
    })
  }
  public static async updateNews(partnerId: number, news: any) {
    return http.put(
      `/companies/${partnerId}/news/${news.id}`,
      formatNewsData(news),
      {
        validateStatus: () => true
      }
    )
  }
  public static getPartnersList(
    searchOptions?: {
      title?: string
      departmentId?: number
      fieldOfActivity?: string[]
      employeesNum?: number | string
      info?: string
    },
    sort: any = null
  ) {
    const params = new URLSearchParams()
    params.append('scopes', 'countLikes')
    if (!_.isEmpty(searchOptions)) {
      params.append('filter', JSON.stringify(searchOptions))
    }
    if (!_.isEmpty(sort)) {
      params.append('sort', JSON.stringify(sort))
    }
    return http.get('/companies', { params })
  }
  public static getOnePartner(partnerId: number) {
    const params = new URLSearchParams()
    params.append('scopes', 'countLikesDislikes')
    params.append('scopes', 'defaultScope')
    return http.get(`/companies/info/${partnerId}`, { params })
  }

  public static getPartnerWithCurators(partnerId: number) {
    const params = new URLSearchParams()
    params.append('scopes', 'withPotentialCurators')
    return http.get(`/companies/info/${partnerId}`, { params })
  }

  public static async updatePartnerInfo(partner: any) {
    const data = PartnersAdapter.castDataForUpdatePartner(partner)
    await http.put(`/companies/${partner.id}/edit`, data)

    const filesData = new FormData()

    if (partner.avatar && partner.avatarCropped) {
      const avatarBlob = await (await fetch(partner.avatar)).blob()
      const avatarFile = new File([avatarBlob], 'avatar.png', {
        type: 'image/png'
      })
      filesData.append('logo', avatarFile)

      const avatarCroppedBlob = await (
        await fetch(partner.avatarCropped)
      ).blob()
      const avatarCroppedFile = new File(
        [avatarCroppedBlob],
        'avatarCropped.png',
        {
          type: 'image/png'
        }
      )

      filesData.append('croppedlogo', avatarCroppedFile)
      filesData.append('croppedAvatarMeta', '0')
    }

    for (const galleryImage of partner.gallery) {
      if (_.isNil(galleryImage.id) && !_.isNil(galleryImage.file)) {
        filesData.append('gallery', galleryImage.file)
      }
    }

    for (const presentationFile of partner.presentation) {
      if (_.isNil(presentationFile.id)) {
        filesData.append('presentation', presentationFile.file)
      }
    }
    await http.post(`/companies/${partner.id}/files`, filesData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  public static deleteFile(partnerId: number, fileId: number) {
    return http.delete(`/companies/${partnerId}/files/${fileId}`)
  }
  public static getNews(
    limit: number,
    offset: number,
    searchOptions?: { title?: string; departmentId: number | null }
  ) {
    const params = new URLSearchParams()
    params.append('limit', String(limit))
    params.append('offset', String(offset))
    if (searchOptions?.title) {
      params.append('title', searchOptions?.title)
    }
    if (!_.isNil(searchOptions?.departmentId)) {
      params.append('departmentId', String(searchOptions?.departmentId))
    }
    return http.get('/companies/news', { params })
  }
  public static setPartnerReaction(partnerId: number, like?: boolean) {
    return http.put(`/companies/${partnerId}/likes`, { isLike: like })
  }
  public static setNewsReaction(newsId: number, like?: boolean) {
    return http.put(`/companies/news/${newsId}/likes`, { isLike: like })
  }
  public static publicateNews(partnerId: number, newsId: number) {
    return http.put(`/companies/${partnerId}/news/${newsId}/await`)
  }
  public static deleteNews(partnerId: number, newsId: number) {
    return http.delete(`/companies/${partnerId}/news/${newsId}`)
  }

  public static switchStatusPractice(companyId: number, practiceId: number) {
    return http.put(
      `/companies/${companyId}/practices/${practiceId}/actuality`,
      {
        isActual: status
      }
    )
  }

  public static async getThemePractices(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ) {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get('/companies/practices/topics', { params })
  }

  public static async getPractice(companyId: number, practiceId: number) {
    return http.get(`/companies/${companyId}/practices/${practiceId}`)
  }

  public static async getThemePractice(
    companyId: number,
    practiceId: number,
    themeId: number,
    withStudents?: boolean
  ) {
    const params = new URLSearchParams()
    if (!_.isNil(withStudents)) {
      params.append('withStudents', withStudents.toString())
    }
    return http.get(
      `/companies/${companyId}/practices/${practiceId}/topics/${themeId}`,
      { params }
    )
  }

  public static async getPracticesWithTopics(companyId: number) {
    const filter = { companyId: companyId, isActual: true }
    const params = new URLSearchParams()
    params.append('filter', JSON.stringify(filter))
    params.append('withTopics', 'true')

    return http.get(`/companies/practices`, { params })
  }

  public static async addPractice(companyId: number, data: any) {
    return http.post(`/companies/${companyId}/practices/`, data)
  }

  public static async deletePractice(companyId: number, practiceId: number) {
    return http.delete(`/companies/${companyId}/practices/${practiceId}`)
  }

  public static async editPractice(companyId: number, data: any) {
    return http.put(`/companies/${companyId}/practices/${data.id}`, data)
  }

  public static async addThemePractice(
    companyId: number,
    practiceId: number,
    data: any
  ) {
    return http.post(
      `/companies/${companyId}/practices/${practiceId}/topics`,
      data
    )
  }
  public static async editThemePractice(
    companyId: number,
    practiceId: number,
    data: any
  ) {
    return http.put(
      `/companies/${companyId}/practices/${practiceId}/topics/${data.id}`,
      data
    )
  }

  public static async deleteThemePractice(
    companyId: number,
    practiceId: number,
    themeId: number
  ) {
    return http.delete(
      `/companies/${companyId}/practices/${practiceId}/topics/${themeId}`
    )
  }

  public static async getPractices(
    limit: number | string,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ) {
    const params = createURLParams(limit, offset, sort, filter, false, true)
    return http.get(`/companies/practices`, { params })
  }

  public static getPracticeDistribution() {
    return http.get('/departmenthead/practices?limit=no&offset=0')
  }

  public static async addStudentToTheme(studentId: number, themeId: number) {
    return http.put('/departmenthead/practices/topics/users', {
      [themeId]: { usersToAddIds: [studentId], usersToRemoveIds: [] }
    })
  }
  public static async removeStudentFromTheme(
    studentId: number,
    themeId: number
  ) {
    return http.put('/departmenthead/practices/topics/users', {
      [themeId]: { usersToRemoveIds: [studentId], usersToAddIds: [] }
    })
  }
  public static async sendStudentsThemeNotifications(data: any) {
    return http.post('/departmenthead/email/students', data)
  }

  public static async exportPracticesTable(
    companyId: number,
    practiceId: number
  ) {
    const response = await http.get(
      `/export/excel/partner/${companyId}/practice/${practiceId}`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `practice-${practiceId}.xlsx`)
  }

  public static getPublicatedEvents(
    limit: number | 'no',
    offset: number | 'no',
    sort?: { [k: string]: any } | null,
    filter?: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get('/events', { params })
  }

  public static getEventsPartner(
    partnerId: number,
    limit: number | 'no',
    offset: number | 'no',
    sort?: { [k: string]: any } | null,
    filter?: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false)
    params.append('countDislikesToo', 'false')
    return http.get(`/events/companies/${partnerId}`, { params })
  }

  public static getOneEventPartner(idEvent: number): AxiosPromise {
    const params = createURLParams()
    params.append('scopes', 'countLikesDislikes')
    return http.get(`/events/${idEvent}`, {
      validateStatus: status => status < 500
    })
  }

  public static async addEventsPartner(
    partnerId: number,
    data: PartnerEventData
  ) {
    return http.post(`/events/companies/${partnerId}`, data)
  }

  public static async editEventsPartner(
    partnerId: number,
    data: PartnerEventData
  ) {
    return http.put(`/events/${data.id}/companies/${partnerId}`, data)
  }

  public static async deleteOneEventPartner(
    partnerId: number,
    eventId: number
  ) {
    return http.delete(`/events/${eventId}/companies/${partnerId}`)
  }

  public static async sendToApprovalOneEventPartner(
    partnerId: number,
    eventId: number
  ) {
    return http.put(`/events/${eventId}/companies/${partnerId}/await`)
  }

  public static async becomeOrganizerEventPartner(eventId: number, data: any) {
    return http.put(`/events/${eventId}/organizers`, data)
  }

  public static async becomeMemberEventPartner(eventId: number) {
    return http.put(`/events/${eventId}/participants`)
  }

  public static getPartnersMainPage(): AxiosPromise {
    return http.get(`/companies/main-page`)
  }

  public static getContacts(companyId: number): AxiosPromise {
    return http.get(`companies/${companyId}/contacts`)
  }

  public static getStaff(companyId: number): AxiosPromise {
    return http.get(`/companies/${companyId}/staff`)
  }

  public static getMyStaff(
    companyId: number,
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, true, true)
    return http.get(`/companies/${companyId}/staff`, { params })
  }

  public static async changeCompany(companyId: number) {
    return http.post(`/auth/company?companyId=${companyId}`)
  }

  public static uploadEventFile(file: any): AxiosPromise {
    const fd = new FormData()
    fd.append('file', file)
    return http.post('/events/file/upload', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public static async exportEstimate(eventId: number) {
    const response = await http.get(`/events/${eventId}/estimate/export`, {
      responseType: 'arraybuffer'
    })
    saveAs(arrayBufferToBlob(response.data), `estimate-${eventId}-table.xlsx`)
  }

  public static getAdditionalTemplateFields(companyId: number): AxiosPromise {
    return http.get(`/companies/${companyId}/additional-fields`)
  }

  public static setPreviewDocumentAdditionalFields(
    companyId: number,
    data: any
  ): AxiosPromise {
    return http.put(
      `/export/word/contracts/projects/preview/${companyId}`,
      data
    )
  }
  public static setAdditionalTemplateFields(
    companyId: number | string,
    data: any
  ): AxiosPromise {
    return http.put(`/companies/${companyId}/additional-fields`, data)
  }

  public static async exportWordStudentPracticeValidate() {
    const response = await http.get(
      '/export/word/contracts/projects/validate',
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `contract-validate.docx`)
  }

  public static async exportWordStudentPractice(companyId: number) {
    const response = await http.get(`/export/word/${companyId}/contract`, {
      responseType: 'arraybuffer'
    })
    saveAs(arrayBufferToBlob(response.data), `contract-${companyId}.docx`)
  }

  public static async exportWordPreview(companyId: number, data: any) {
    const params = new URLSearchParams(data)

    const response = await http.get(
      `/export/word/contracts/projects/preview/${companyId}?${params}`,
      {
        responseType: 'arraybuffer'
      }
    )
    return arrayBufferToBlob(response.data)
  }

  public static async exportWordStudentPracticeTemplate() {
    const response = await http.get(
      `/export/word/contracts/projects/template/download`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `contract-template.docx`)
  }

  public static uploadWordStudentPracticeTemplate(file: any): AxiosPromise {
    const fd = new FormData()
    fd.append('file', file)
    return http.put('/export/word/contracts/projects/template/upload', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
