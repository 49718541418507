export enum roles {
  'admin' = 'admin', // Администратор
  'teacher' = 'teacher', // Преподаватель
  'student' = 'student', // Студент
  'partner' = 'partner', // Партнер
  'PRManager' = 'PRManager', // Сотрудник ЦПАТ
  'LoadManager' = 'LoadManager' // Ответственный за нагрузку
}

export const rolesTranslation: { [k: string]: string } = {
  [roles.admin]: 'Администратор',
  [roles.teacher]: 'Преподаватель',
  [roles.student]: 'Студент',
  [roles.partner]: 'Партнер',
  [roles.PRManager]: 'Сотрудник ЦПАТ',
  [roles.LoadManager]: 'Ответственный за нагрузку'
}

export const rolesBadges: { [k: string]: string } = {
  [roles.admin]: 'А',
  [roles.teacher]: 'Преп',
  [roles.student]: 'Cтуд',
  [roles.partner]: 'Парт',
  [roles.PRManager]: 'ЦПАТ',
  [roles.LoadManager]: 'Нагр'
}
