





























import { Component, Vue } from 'vue-property-decorator'
import { userMappers } from '@/store/modules/user'
import { rolesTranslation } from '@/config/roles'
import { PermissionManager } from '@/api/permissions/AbilityBuilder'
import dateFormat from '@/config/dateFormat'
import moment from 'moment'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters([
      'currentUser',
      'userRoles',
      'userDepartments',
      'lkDepartments',
      'partnerPosition',
      'isBannedUser',
      'banReason',
      'banDate'
    ])
  }
})

@Component
export default class Profile extends Mappers {
  private getInitials() {
    return this.currentUser && this.currentUser.initials
      ? this.currentUser.initials
      : 'Неизвестный'
  }

  private getRoles() {
    return this.userRoles.map((r: string) => rolesTranslation[r]).join(', ')
  }
  private getDepartments() {
    return this.userDepartments.map(d => d.shortTitle).join(', ')
  }
  private getDepartmentsFromLK() {
    return this.lkDepartments
      .map(d => `${d.workPosition.shortTitle} ${d.workDepartment.shortTitle}`)
      .join(', ')
  }

  private getBan() {
    return `Дата блокировки: ${
      this.banDate
        ? moment(this.banDate).format(dateFormat.date.localFormat)
        : 'неизвестно'
    }<br>Причина: ${this.banReason ? this.banReason : 'не указана'}`
  }

  private static generateDescription(title: string, list?: string[]) {
    let text = ''
    if (list && list.length) {
      text += `<b>${title}:</b><br>`
      text += list.join('<br>')
      text += '<br><br>'
    }
    return text
  }

  private getRightsDescription() {
    let text = ''

    text += Profile.generateDescription(
      'Права',
      PermissionManager.translateAbility(PermissionManager.common())
    )

    if (!text) return 'Пользователь не имеет дополнительные права.'

    return text
  }
}
