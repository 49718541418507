


















import { Component, Vue } from 'vue-property-decorator'

import { toastMapper } from '@/store/modules/toast'

const Mappers = Vue.extend({
  computed: {
    ...toastMapper.mapGetters(['toasts'])
  },
  methods: {
    ...toastMapper.mapActions(['delToast'])
  }
})

@Component
export default class Toaster extends Mappers {
  private onHideToast(id: number, e: any) {
    e.preventDefault()
    setTimeout(() => {
      this.delToast(id)
    }, 0)
  }

  private updateToasts() {
    setTimeout(() => {
      this.$bvToast.show('example-toast')
    }, 0)
  }

  private onShown(id: number, time: number) {
    setTimeout(
      () => {
        this.delToast(id)
      },
      time ? time * 1000 : 5000
    )
  }

  private getStyle(toast: any) {
    if (toast.error) {
      return 'b-toast-error'
    } else if (toast.variant) {
      return `b-toast-${toast.variant}`
    } else {
      return 'b-toast-default'
    }
  }

  private get toastList() {
    this.updateToasts()
    return this.toasts
  }
}
