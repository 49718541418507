import { Getters, Mutations, Module, createMapper } from 'vuex-smart-module'
import Config from '@/config/config.ts'
import { Vue } from 'vue-property-decorator'

class MobileWidthState {
  isMobile = false
  windowWidth = Config.defaultWidth
  windowHeight = Config.defaultHeight
}

class MobileWidthGetters extends Getters<MobileWidthState> {
  get isMobile() {
    return this.state.isMobile
  }
  get windowWidth() {
    return this.state.windowWidth
  }
  get windowHeight() {
    return this.state.windowHeight
  }
}

class MobileWidthMutations extends Mutations<MobileWidthState> {
  setWindowWidth(w: number) {
    Vue.set(this.state, 'windowWidth', w)
    Vue.set(this.state, 'isMobile', w <= Config.mobileWidth)
  }
  setWindowHeight(h: number) {
    Vue.set(this.state, 'windowHeight', h)
  }
}

export const mobileWidth = new Module({
  state: MobileWidthState,
  getters: MobileWidthGetters,
  mutations: MobileWidthMutations
})

export const mobileWidthMapper = createMapper(mobileWidth)
