




import { Component, Vue } from 'vue-property-decorator'
import Home from '@/components/pages/home/index.vue'

@Component({ components: { Home } })
export default class HomeView extends Vue {}
