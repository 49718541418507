import { ifAuthenticated } from '@/router/before'

export const redirectLogic = [
  {
    path: '/main-redirect',
    name: 'RedirectLogic',
    meta: {
      isDisplayInSidebar: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/*webpackChunkName: "RedirectLogic"*/ '@/views/RedirectLogic.vue')
  }
]
