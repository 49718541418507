import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Config from '@/config/config'
import HomeDefault from '@/components/pages/home/components/HomeDefault.vue'
import { beforeEachRoute } from '@/router/routerLogic'
import { afterEachRoute } from '@/router/after'
import { adminPages } from '@/router/pages/admin'
import { authPages } from '@/router/pages/auth'
import { devPages } from '@/router/pages/dev'
import { commonPages } from '@/router/pages/common'
import { partnerPages } from '@/router/pages/partner'
import { newsPages } from '@/router/pages/news'
import { eventPages } from '@/router/pages/events'
import { practicePages } from '@/router/pages/practices'
import { emManagerPages } from '@/router/pages/em-manager'
import { projectPages } from '@/router/pages/projects'
import { vacancyPages } from '@/router/pages/vacancies'
import { resumePages } from '@/router/pages/resume'
import { dialogPages } from '@/router/pages/dialogs'
import { redirectLogic } from '@/router/pages/redirect'

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeDefault,
    redirect: Config.routing.homePage.path,
    children: [
      ...partnerPages,
      ...emManagerPages,

      ...newsPages,
      ...projectPages,
      ...eventPages,
      ...vacancyPages,
      ...resumePages,
      ...practicePages,
      ...dialogPages,

      ...adminPages,
      ...devPages,
      ...commonPages,
      ...authPages,
      ...redirectLogic
    ]
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEachRoute)
router.afterEach(afterEachRoute)
