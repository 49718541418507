import { ifAuthenticated } from '@/router/before'

export const eventRouteNames = {
  EventListRoute: 'EventListRoute',
  EventViewRoute: 'EventViewRoute',
  EventCardsRoute: 'EventCardsRoute',
  EventEmManagerApproveRoute: 'EventEmManagerApproveRoute',
  EventEmManagerRequestRoute: 'EventEmManagerRequestRoute'
}
const rn = eventRouteNames

export const eventRouteURLs = {
  [rn.EventListRoute]: '/partner/:partnerId/events',
  [rn.EventViewRoute]: '/partner/:partnerId/events/:eventId',
  [rn.EventCardsRoute]: '/events',
  [rn.EventEmManagerApproveRoute]: '/events/approve',
  [rn.EventEmManagerRequestRoute]: '/events/request'
}

export const eventPages = [
  {
    // События партнера в виде списка с возможностью редактирования
    path: eventRouteURLs[rn.EventListRoute],
    name: rn.EventListRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'calendar'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Мероприятия',
        displayName: 'Мероприятия организации'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Мероприятия'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EventListRoute" */ '@/views/events/EventListRoute.vue'
      )
  },
  {
    // Событие партнера
    path: eventRouteURLs[rn.EventViewRoute],
    name: rn.EventViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Мероприятия',
        displayName: 'Страница мероприятия'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EventViewRoute" */ '@/views/events/EventViewRoute.vue'
      )
  },
  {
    // Все события в виде карточек
    path: eventRouteURLs[rn.EventCardsRoute],
    name: rn.EventCardsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'calendar'],
        isDisplay: true
      },
      navbar: {
        group: 'Мероприятия',
        displayName: 'Все мероприятия'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EventCardsRoute" */ '@/views/events/EventCardsRoute.vue'
      )
  },

  {
    // Страница ЦПАТ для согласования событий
    path: eventRouteURLs[rn.EventEmManagerApproveRoute],
    name: rn.EventEmManagerApproveRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'trophy'],
        isDisplay: true
      },
      navbar: {
        group: 'Мероприятия',
        displayName: 'Согласование'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EventEmManagerApproveRoute" */ '@/views/events/EventEmManagerApproveRoute.vue'
      )
  },
  {
    path: eventRouteURLs[rn.EventEmManagerRequestRoute],
    name: rn.EventEmManagerRequestRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'handshake'],
        isDisplay: true
      },
      navbar: {
        group: 'Мероприятия',
        displayName: 'Заявки на участие'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EventEmManagerRequestRoute" */ '@/views/events/EventEmManagerRequestRoute.vue'
      )
  }
]
