import { defineAbility } from '@casl/ability'
import { PermissionManager } from '@/api/permissions/AbilityBuilder'

export enum permissionsPartner {
  chats = 'chats',
  vacancies = 'vacancies',
  projects = 'projects',
  events = 'events',
  news = 'news',
  practice = 'practice',
  resume = 'resume',
  invite = 'invite'
}

export const permissionsPartnerTranslation = {
  [permissionsPartner.chats]: 'Диалоги со студентами',
  [permissionsPartner.vacancies]: 'Создание/редактирование вакансий',
  [permissionsPartner.projects]: 'Создание/редактирование проектов',
  [permissionsPartner.events]: 'Создание/редактирование мероприятий',
  [permissionsPartner.news]: 'Создание/редактирование новостей',
  [permissionsPartner.practice]: 'Создание/редактирование практик',
  [permissionsPartner.resume]: 'Просмотр резюме студентов',
  [permissionsPartner.invite]: 'Приглашение сотрудников'
}

export const permissionsPartnerShortTranslation = {
  [permissionsPartner.chats]: 'Диалоги',
  [permissionsPartner.vacancies]: 'Вакансии',
  [permissionsPartner.projects]: 'Проекты',
  [permissionsPartner.events]: 'Мероприятия',
  [permissionsPartner.news]: 'Новости',
  [permissionsPartner.practice]: 'Практики',
  [permissionsPartner.resume]: 'Резюме',
  [permissionsPartner.invite]: 'Сотрудники'
}

export const permissionsPartnerBadge = {
  [permissionsPartner.chats]: 'Диал.',
  [permissionsPartner.vacancies]: 'Вак.',
  [permissionsPartner.projects]: 'Проек.',
  [permissionsPartner.events]: 'Меропр.',
  [permissionsPartner.news]: 'Нов.',
  [permissionsPartner.practice]: 'Прак.',
  [permissionsPartner.resume]: 'Рез.',
  [permissionsPartner.invite]: 'Сотр.'
}

export default class CommonPartnerPermissions {
  public static common(company?: any) {
    return defineAbility(can => {
      const permissions = company
        ? company.permissions
        : PermissionManager.user('partnerPermissions')

      for (const permission of permissions) {
        can(permission, undefined)
      }
    })
  }
}
