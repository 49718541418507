import _ from 'lodash'
import { clientBase } from '@/api/httpAxios'

export function getRootedUrl(url: string) {
  return _.isString(url) &&
    (url.startsWith('http://') || url.startsWith('https://'))
    ? url
    : '//' + url
}

export function routeBuild(
  url: string,
  options: { [k: string]: string | number } = {}
) {
  let result = url
  for (const [key, value] of Object.entries(options)) {
    result = result.replace(`:${key}`, value.toString())
  }
  return result
}

export function buildURL(
  url: string,
  options: { [k: string]: string | number } = {}
) {
  return `${clientBase}${routeBuild(url, options)}`
}
