export enum permissions {
  viewPage = 'viewPage',
  adminSystem = 'adminSystem',
  editOrganisationPage = 'editOrganisationPage',
  chatWithStudents = 'chatWithStudents',
  chatWithPartners = 'chatWithPartners',
  invitePartners = 'invitePartners',
  viewNotificationTabs = 'viewNotificationTabs',
  createOrganisations = 'createOrganisations',
  banUser = 'banUser',
  viewStatistics = 'viewStatistics',
  deputyDepHead = 'deputyDepHead'
}

export const permissionsTranslation: { [k: string]: string } = {
  [permissions.deputyDepHead]: 'Исполняющий обязанности зав.кафедрой',
  [permissions.adminSystem]: 'Администрирование системы',
  [permissions.editOrganisationPage]: 'Редактирование страницы партнера',
  [permissions.chatWithStudents]: 'Диалоги со студентами',
  [permissions.chatWithPartners]: 'Диалоги с партнерами',
  [permissions.viewNotificationTabs]: 'Просмотр уведомлений',
  [permissions.invitePartners]: 'Приглашение сотрудников-партнеров в систему',
  [permissions.createOrganisations]: 'Добавление партнера',
  [permissions.banUser]: 'Блокировка/разблокировка пользователей',
  [permissions.viewStatistics]: 'Просмотр статистики'
}
