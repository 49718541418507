














import { Component, Vue } from 'vue-property-decorator'
import { LIMIT_PAGINATION_BELL } from '@/config/notificationTypes'
import { notificationsMapper } from '@/store/modules/notifications'
import NotificationsModal from '@/components/pages/home/components/navbar/components/NotificationsModal.vue'
import NotificationBell from 'vue-notification-bell'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapGetters(['unreadCount'])
  }
})

@Component({ components: { NotificationBell, NotificationsModal } })
export default class NotificationBellComponent extends Mappers {
  private LIMIT_PAGINATION_BELL_ = LIMIT_PAGINATION_BELL

  private onBellClick() {
    this.$bvModal.show('notificationsModal')
  }
}
