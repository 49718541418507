




























import { Prop, Vue, Component } from 'vue-property-decorator'
import { routeBuild } from '@/utils/urls'

@Component
export default class RouteCard extends Vue {
  @Prop(Object) readonly route!: any

  private buildPath(url: string) {
    const options = this.route.meta.sidebar?.routeBuildOptions
    return options ? routeBuild(url, options) : url
  }
}
