import { ifAuthenticated } from '@/router/before'

export const partnerRouteNames = {
  PartnerCardsRoute: 'PartnerCardsRoute',
  PartnerViewRoute: 'PartnerViewRoute',
  PartnerEditRoute: 'PartnerEditRoute',
  PartnerEmployeesRoute: 'PartnerEmployeesRoute',
  PartnerEducationProgramsRoute: 'PartnerEducationProgramsRoute',
  PartnerEducationSubjectsRoute: 'PartnerEducationSubjectsRoute',
  PartnerParticipationEventsRoute: 'PartnerParticipationEventsRoute',
  PartnerInterestsRoute: 'PartnerInterestsRoute'
}
const rn = partnerRouteNames

export const partnerRouteURLs = {
  [rn.PartnerCardsRoute]: '/partners',
  [rn.PartnerViewRoute]: '/partners/:partnerId/about',
  [rn.PartnerEditRoute]: '/partners/:partnerId/about/edit',
  [rn.PartnerEmployeesRoute]: '/partners/:partnerId/employees',
  [rn.PartnerEducationProgramsRoute]: '/partners/:partnerId/education-program',
  [rn.PartnerEducationSubjectsRoute]: '/partners/:partnerId/education-subjects',
  [rn.PartnerParticipationEventsRoute]:
    '/partners/:partnerId/participation-events',
  [rn.PartnerInterestsRoute]: '/partners/:partnerId/interests'
}

export const partnerPages = [
  {
    path: partnerRouteURLs[rn.PartnerCardsRoute],
    name: rn.PartnerCardsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'list-ul'],
        isDisplay: true
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Список партнеров'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerCardsRoute" */ '@/views/partner/PartnerCardsRoute.vue'
      )
  },

  {
    path: partnerRouteURLs[rn.PartnerViewRoute],
    name: rn.PartnerViewRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'address-card'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'О компании'
      },
      breadcrumbs: {
        isDisplay: true
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerViewRoute" */ '@/views/partner/PartnerViewRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerEditRoute],
    name: rn.PartnerEditRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Партнеры',
        displayName: 'Редактирование'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerEditRoute" */ '@/views/partner/PartnerEditRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerEmployeesRoute],
    name: rn.PartnerEmployeesRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'user'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Список контактных лиц'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerEmployeesRoute" */ '@/views/partner/PartnerEmployeesRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerEducationProgramsRoute],
    name: rn.PartnerEducationProgramsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'graduation-cap'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Образовательные программы'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Обр. программы'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerEducationProgramsRoute" */ '@/views/partner/PartnerEducationProgramsRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerEducationSubjectsRoute],
    name: rn.PartnerEducationSubjectsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'book'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Дисциплины'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Дисциплины'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerEducationSubjectsRoute" */ '@/views/partner/PartnerEducationSubjectsRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerParticipationEventsRoute],
    name: rn.PartnerParticipationEventsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'trophy'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Партнеры',
        displayName: 'Дополнительные интересы'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Доп. интересы'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerParticipationEventsRoute" */ '@/views/partner/PartnerParticipationEventsRoute.vue'
      )
  },
  {
    path: partnerRouteURLs[rn.PartnerInterestsRoute],
    name: rn.PartnerInterestsRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Партнеры',
        displayName: 'Интересы'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PartnerInterestsRoute" */ '@/views/partner/PartnerInterestsRoute.vue'
      )
  }
]
