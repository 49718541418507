










import { Component, Vue } from 'vue-property-decorator'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import { userMappers } from '@/store/modules/user'
import Config from '@/config/config.ts'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['isAuthenticated']),
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({ mixins: [RouterEventWrapper] })
export default class Login extends Mappers {
  routerPush!: Function

  get isDisplayEnterButton() {
    return (
      !this.isAuthenticated && this.$route.path !== Config.routing.authPage.path
    )
  }
  private onLogin() {
    this.routerPush(Config.routing.authPage.path)
  }
}
