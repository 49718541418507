





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi, IDatasource, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'

import ActionRenderer from '@/components/ag-grid/renderers/ActionRenderer.vue'
import RoleRenderer from '@/components/ag-grid/renderers/RoleRenderer'
import SetFilter from '@/components/ag-grid/filters/SetFilter'

import { rolesTranslation } from '@/config/roles'

import { User } from '@/api/user'
import { formatLkUserDepartments } from '@/utils/formatter'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import AgGridFactory from '@/api/factories/agGridFactory'
import { partnerRouteNames, partnerRouteURLs } from '@/router/pages/partner'
import { buildURL, routeBuild } from '@/utils/urls'
import { projectRouteNames, projectRouteURLs } from '@/router/pages/projects'
import moment from 'moment'
import dateFormat from '@/config/dateFormat'
import StudentInviteBadgeRenderer from '@/components/ag-grid/renderers/StudentInviteBadgeRenderer.vue'
import ProjectStudentStatusRenderer from '@/components/ag-grid/renderers/ProjectStudentStatusRenderer'
import { statusTranslate } from '@/config/projects/status'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({
  components: { AgGridVue, ActionRenderer }
})
export default class UsersTable extends Mappers {
  @Prop(Object) readonly dataSource: IDatasource | undefined
  @Prop(Array) readonly rowData: User[] | undefined
  @Prop(Function) readonly onEditCallback!: Function
  @Prop(Function) readonly onDoubleClickCallback!: Function
  @Prop(Array) readonly actions?: string[]
  @Prop({ type: Boolean, default: false }) readonly isDisplayUserId!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayProject!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayPartner!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayStatus!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayDate!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayGroup!: boolean
  @Prop({ type: Boolean, default: true }) readonly isDisplayEmail!: boolean
  @Prop({ type: Boolean, default: false }) readonly isDisplayRoles!: boolean
  @Prop({ type: Boolean, default: false })
  readonly isDisplayLKDepartments!: boolean
  @Prop({ type: Boolean, default: false })
  readonly isDisplayDegrees!: boolean
  @Prop({ type: Boolean, default: false })
  readonly isDisplayDepartments!: boolean
  @Prop({ type: String, default: '' })
  readonly departmentTitle!: boolean
  @Prop({ type: Boolean, default: true }) readonly isDisplayHeader!: boolean
  @Prop({ type: String, default: 'Список пользователей' })
  readonly headerText!: boolean
  @Prop({ type: String, default: 'height: 75vh; width: 100%;' })
  readonly tableStyle!: string

  private gridApi!: GridApi

  private defaultDefs = [
    {
      headerName: 'id',
      field: 'id',
      colId: 'id',
      width: 80,
      minWidth: 80,
      sort: this.isDisplayUserId ? 'asc' : null,
      hide: !this.isDisplayUserId,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      width: 120,
      minWidth: 120
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      width: 120,
      minWidth: 120
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      width: 150,
      minWidth: 150
    },
    {
      headerName: 'Группа',
      field: 'group',
      colId: 'group',
      width: 100,
      minWidth: 100,
      hide: !this.isDisplayGroup
    },
    {
      headerName: 'Кафедра',
      field: 'aisDepartments',
      colId: 'aisDepartments',
      width: 120,
      minWidth: 120,
      sortable: false,
      hide: !this.isDisplayDepartments,
      valueFormatter(params: any) {
        return params.value
          ? _.isArray(params.value)
            ? params.value.map((v: any) => v.shortTitle).join(', ')
            : params.value
          : ''
      }
    },
    {
      headerName: 'Статус заявки',
      field: 'status',
      colId: 'status',
      cellRenderer: ProjectStudentStatusRenderer,
      width: 100,
      minWidth: 100,
      hide: !this.isDisplayStatus,
      filter: 'SetFilter',
      filterParams: {
        filterKeys: Object.keys(statusTranslate),
        keyFormatter(key: string) {
          return statusTranslate[key]
        }
      }
    },
    {
      headerName: 'Дата последнего изменения',
      field: 'updatedAt',
      colId: 'updatedAt',
      width: 150,
      hide: !this.isDisplayDate,
      suppressSizeToFit: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true,
        filterOptions: ['greaterThan', 'lessThan', 'inRange']
      },
      valueFormatter({ node }: { node: any }) {
        return node.data && node.data.updatedAt
          ? moment(node.data.updatedAt).format(dateFormat.date.dateTime)
          : ''
      }
    },
    {
      headerName: 'Почта',
      field: 'email',
      colId: 'email',
      width: 140,
      minWidth: 140,
      hide: !this.isDisplayEmail
    },
    {
      headerName: 'Подразделения',
      field: 'userDepartments',
      colId: 'userDepartments',
      width: 200,
      minWidth: 120,
      sortable: false,
      filter: false,
      hide: !this.isDisplayLKDepartments,
      valueFormatter(params: any) {
        if (_.isEmpty(params.value)) {
          return ''
        }
        return formatLkUserDepartments(params.value)
      }
    },
    {
      headerName: 'Роли',
      field: 'roles',
      colId: 'roles',
      width: 150,
      minWidth: 150,
      sortable: false,
      cellRenderer: RoleRenderer,
      hide: !this.isDisplayRoles,
      filter: 'SetFilter',
      filterParams: {
        filterKeys: Object.keys(rolesTranslation),
        keyFormatter(key: string) {
          return rolesTranslation[key]
        }
      }
    },
    {
      headerName: 'Проект',
      field: 'project',
      colId: 'project',
      width: 250,
      minWidth: 250,
      hide: !this.isDisplayProject,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => {
        if (_.isNil(params.value)) {
          return ''
        }
        const url = projectRouteURLs[projectRouteNames.ProjectViewRoute]
        const route = buildURL(url, {
          partnerId: params.value.companyId,
          projectId: params.value.projectId
        })
        return `
           <a  href="${route}"target="_blank">
              ${params.value.title}
           </a>`
      }
    },
    {
      headerName: 'Партнер',
      field: 'company',
      colId: 'company',
      width: 250,
      minWidth: 250,
      sortable: false,
      hide: !this.isDisplayPartner,
      cellRenderer: (params: ICellRendererParams) => {
        if (_.isNil(params.value)) {
          return ''
        }
        const url = partnerRouteURLs[partnerRouteNames.PartnerViewRoute]
        const route = buildURL(url, {
          partnerId: params.value.companyId
        })
        return `
           <a  href="${route}"target="_blank">
              ${params.value.title}
           </a>`
      }
    },
    ...(this.actions
      ? [
          {
            ...AgGridFactory.getActionColumn({
              width: this.actions.length * 60 + 20,
              cellRendererParams: {
                ...this.getActionCallbacks()
              }
            })
          }
        ]
      : [])
  ]

  @Watch('dataSource')
  private onDatasourceChange(dataSource: IDatasource) {
    this.gridApi?.setDatasource(dataSource)
  }

  private getActionCallbacks() {
    const callbacks: { [k: string]: (p: ICellRendererParams) => void } = {}
    for (const action of this.actions ?? []) {
      const actionName = `on${action[0].toUpperCase()}${action.slice(1)}`
      callbacks[actionName] = (params: ICellRendererParams) => {
        this.$emit(action, params)
      }
    }
    return callbacks
  }

  public static isOldData(params: ICellRendererParams) {
    return moment(new Date()).diff(params.data?.updatedAt, 'months') >= 12
      ? { background: ' #d0cccc' }
      : null
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    onGridReady: this.onGridReady,
    ...(this.dataSource
      ? {
          rowModelType: 'infinite'
        }
      : {}),
    cacheBlockSize: 15,
    rowBuffer: 100,
    pagination: true,
    paginationPageSize: 15,
    onCellClicked: this.onEditCallback,
    onCellDoubleClicked: this.onDoubleClickCallback,
    components: {
      RoleRenderer,
      ProjectStudentStatusRenderer
    },
    frameworkComponents: {
      SetFilter,
      ActionRenderer
    },
    ...(this.columnDefs.find(def => def.colId === 'updatedAt' && !def.hide)
      ? { getRowStyle: UsersTable.isOldData }
      : {})
  }

  private onGridReady({ api }: { api: GridApi }) {
    if (!this.isMobile) {
      api.sizeColumnsToFit()
    }
    if (this.dataSource) {
      api.setDatasource(this.dataSource as IDatasource)
    } else if (this.rowData) {
      api.setRowData(this.rowData)
    }
    this.gridApi = api
  }

  private get columnDefs() {
    return this.defaultDefs
  }

  @Watch('rowData')
  private onRowDataChange(newRowData: User[]) {
    this.gridApi!.setRowData(newRowData)
  }

  private reDrawTable() {
    if (this.gridApi) {
      if (this.dataSource) {
        this.gridApi!.setDatasource(this.dataSource)
      } else if (this.rowData) {
        this.gridApi!.setRowData(this.rowData)
      }
    }
  }
}
