import { defineAbility } from '@casl/ability'
import store from '@/store'
import CommonPartnerPermissions, {
  permissionsPartner
} from '@/api/permissions/CommonPartnerPermissions'
import { permissions } from '@/api/permissions/permissions'
import { projectRouteNames } from '@/router/pages/projects'
import { authRouteNames } from '@/router/pages/auth'
import { adminRouteNames } from '@/router/pages/admin'
import { newsRouteNames } from '@/router/pages/news'
import { devRouteNames } from '@/router/pages/dev'
import { eventRouteNames } from '@/router/pages/events'
import { dialogRouteNames } from '@/router/pages/dialogs'
import { commonRouteNames } from '@/router/pages/common'
import { emManagerRouteNames } from '@/router/pages/em-manager'
import { practiceRouteNames } from '@/router/pages/practices'
import { vacancyRouteNames } from '@/router/pages/vacancies'
import { partnerRouteNames } from '@/router/pages/partner'
import { resumeRouteNames } from '@/router/pages/resume'

export class PermissionPageManager {
  // Права на просмотр страниц (Sidebar)
  public static sidebar() {
    const pageList = PermissionPageManager._pages()
    return defineAbility(can => {
      if (!this.user('isPartner')) {
        PermissionPageManager.removeRule(
          partnerRouteNames.PartnerViewRoute,
          pageList
        )
      }

      if (this.user('isPRManager')) {
        PermissionPageManager.removeRule(
          partnerRouteNames.PartnerEducationProgramsRoute,
          pageList
        )
        PermissionPageManager.removeRule(
          partnerRouteNames.PartnerEducationSubjectsRoute,
          pageList
        )
        PermissionPageManager.removeRule(
          partnerRouteNames.PartnerParticipationEventsRoute,
          pageList
        )

        PermissionPageManager.removeRule(newsRouteNames.NewsListRoute, pageList)
        PermissionPageManager.removeRule(
          projectRouteNames.ProjectListRoute,
          pageList
        )
        PermissionPageManager.removeRule(
          eventRouteNames.EventListRoute,
          pageList
        )
        PermissionPageManager.removeRule(
          vacancyRouteNames.VacancyListRoute,
          pageList
        )

        PermissionPageManager.removeRule(
          practiceRouteNames.PracticeListRoute,
          pageList
        )
      }

      for (const page of Object.keys(pageList)) {
        can(permissions.viewPage, page)
      }
    })
  }

  public static _pages() {
    const rules: any = {}

    if (this.user('isAuthenticated')) {
      // Для авторизованных пользователей
      PermissionPageManager.addRule(authRouteNames.LogoutRoute, rules)
      PermissionPageManager.addRule(commonRouteNames.NotificationsRoute, rules)
      PermissionPageManager.addRule(commonRouteNames.ChangelogRoute, rules)

      PermissionPageManager.addRule(eventRouteNames.EventViewRoute, rules)
      PermissionPageManager.addRule(newsRouteNames.NewsViewRoute, rules)

      PermissionPageManager.addRule(
        partnerRouteNames.PartnerInterestsRoute,
        rules
      )

      PermissionPageManager.addRule(projectRouteNames.ProjectViewRoute, rules)

      // Скрываем от чисто студентов
      if (!(this.user('userRoles').length === 1 && this.user('isStudent'))) {
        PermissionPageManager.addRule(projectRouteNames.ProjectMyRoute, rules)
      }

      if (this.user('isAdmin')) {
        PermissionPageManager.addRule(adminRouteNames.ActivityRoute, rules)
        PermissionPageManager.addRule(adminRouteNames.ServerLogsRoute, rules)
        PermissionPageManager.addRule(adminRouteNames.SessionsRoute, rules)
        PermissionPageManager.addRule(adminRouteNames.HistoryRoute, rules)
        PermissionPageManager.addRule(adminRouteNames.BuildInfoRoute, rules)
        PermissionPageManager.addRule(
          adminRouteNames.UserPermissionsRoute,
          rules
        )
        PermissionPageManager.addRule(devRouteNames.ServerAPIRoute, rules)

        if (!this.user('isFakeAuth') && process.env.NODE_ENV === 'production') {
          PermissionPageManager.addRule(devRouteNames.FakeAuthRoute, rules)
        }
      }
      if (this.user('isStudent')) {
        PermissionPageManager.addRule(resumeRouteNames.ProfileRoute, rules)
      }
      if (!this.user('isBannedUser')) {
        PermissionPageManager.addRule(dialogRouteNames.DialogUserRoute, rules)
      }

      if (this.user('isPartner') || this.user('isPRManager')) {
        PermissionPageManager.addRule(partnerRouteNames.PartnerEditRoute, rules)
        PermissionPageManager.addRule(
          partnerRouteNames.PartnerEmployeesRoute,
          rules
        )
        PermissionPageManager.addRule(
          partnerRouteNames.PartnerEducationProgramsRoute,
          rules
        )
        PermissionPageManager.addRule(
          partnerRouteNames.PartnerEducationSubjectsRoute,
          rules
        )
        PermissionPageManager.addRule(
          partnerRouteNames.PartnerParticipationEventsRoute,
          rules
        )

        const permissions = CommonPartnerPermissions.common()
        if (permissions.can(permissionsPartner.chats, undefined)) {
          PermissionPageManager.addRule(dialogRouteNames.DialogUserRoute, rules)
        }

        if (
          permissions.can(permissionsPartner.events, undefined) ||
          this.user('isPRManager')
        ) {
          PermissionPageManager.addRule(eventRouteNames.EventListRoute, rules)
        }

        if (
          permissions.can(permissionsPartner.news, undefined) ||
          this.user('isPRManager')
        ) {
          PermissionPageManager.addRule(newsRouteNames.NewsListRoute, rules)
        }

        // if (
        //   permissions.can(permissionsPartner.practice, undefined) ||
        //   this.user('isPRManager')
        // ) {
        //   PermissionPageManager.addRule(
        //     practiceRouteNames.PracticeListRoute,
        //     rules
        //   )
        // }

        if (
          permissions.can(permissionsPartner.projects, undefined) ||
          this.user('isPRManager')
        ) {
          PermissionPageManager.addRule(
            projectRouteNames.ProjectListRoute,
            rules
          )
          PermissionPageManager.addRule(
            projectRouteNames.ProjectAddRoute,
            rules
          )
        }

        if (
          permissions.can(permissionsPartner.vacancies, undefined) ||
          this.user('isPRManager')
        ) {
          PermissionPageManager.addRule(
            vacancyRouteNames.VacancyListRoute,
            rules
          )
        }

        if (permissions.can(permissionsPartner.resume, undefined)) {
          PermissionPageManager.addRule(resumeRouteNames.ResumeViewRoute, rules)
          PermissionPageManager.addRule(
            resumeRouteNames.ResumeCardsRoute,
            rules
          )
        }
      }

      if (this.user('isPRManager') || this.user('hasCuratedProjects')) {
        PermissionPageManager.addRule(resumeRouteNames.ResumeViewRoute, rules)
        PermissionPageManager.addRule(resumeRouteNames.ResumeCardsRoute, rules)
        PermissionPageManager.addRule(
          projectRouteNames.ProjectStudentsRoute,
          rules
        )
      }

      if (this.user('isPRManager')) {
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerPartnersRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerUserDeleteRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerComplaintRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerHistoryRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerStatisticsRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerDictsRoute,
          rules
        )
        PermissionPageManager.addRule(
          emManagerRouteNames.EmManagerOrderPriorityRoute,
          rules
        )

        PermissionPageManager.addRule(
          eventRouteNames.EventEmManagerApproveRoute,
          rules
        )
        PermissionPageManager.addRule(
          eventRouteNames.EventEmManagerRequestRoute,
          rules
        )

        PermissionPageManager.addRule(
          vacancyRouteNames.VacancyEmManagerApproveRoute,
          rules
        )

        PermissionPageManager.addRule(
          newsRouteNames.NewsEmManagerApproveRoute,
          rules
        )
      }

      if (this.user('isDepHeaded') || this.user('isAssignedLeaderOfAnyDep')) {
        // PermissionPageManager.addRule(
        //   practiceRouteNames.PracticeDepHeadManagementRoute,
        //   rules
        // )
        PermissionPageManager.addRule(
          projectRouteNames.ProjectDepHeadManagementRoute,
          rules
        )
      }

      if (this.user('isPartner') && !this.user('isPRManager')) {
        PermissionPageManager.removeRule(
          projectRouteNames.ProjectStudentsRoute,
          rules
        )
      }

      if (process.env.NODE_ENV !== 'production') {
        PermissionPageManager.addRule(devRouteNames.ChangeRoleRoute, rules)
        PermissionPageManager.addRule(devRouteNames.ChangeUserRoute, rules)
        PermissionPageManager.addRule(devRouteNames.ServerAPIRoute, rules)
      }
    }

    PermissionPageManager.addRule(authRouteNames.AuthMainRoute, rules)

    if (!this.user('isAuthenticated')) {
      PermissionPageManager.addRule(authRouteNames.AuthPartnerRoute, rules)
      PermissionPageManager.addRule(
        authRouteNames.RegistrationPartnerRoute,
        rules
      )
      PermissionPageManager.addRule(authRouteNames.PasswordRecoveryRoute, rules)
    }

    // Для всех пользователей
    PermissionPageManager.addRule(commonRouteNames.HelpRoute, rules)
    PermissionPageManager.addRule(commonRouteNames.ErrorRoute, rules)
    PermissionPageManager.addRule(commonRouteNames.MainPageRoute, rules)

    PermissionPageManager.addRule(eventRouteNames.EventCardsRoute, rules)
    PermissionPageManager.addRule(newsRouteNames.NewsCardsRoute, rules)

    PermissionPageManager.addRule(partnerRouteNames.PartnerCardsRoute, rules)
    PermissionPageManager.addRule(partnerRouteNames.PartnerViewRoute, rules)

    // PermissionPageManager.addRule(practiceRouteNames.PracticeViewRoute, rules)
    // PermissionPageManager.addRule(practiceRouteNames.PracticeTopicsRoute, rules)

    PermissionPageManager.addRule(projectRouteNames.ProjectCardsRoute, rules)

    PermissionPageManager.addRule(vacancyRouteNames.VacancyCardsRoute, rules)
    PermissionPageManager.addRule(vacancyRouteNames.VacancyViewRoute, rules)

    // TODO fix
    PermissionPageManager.addRule('RedirectLogic', rules)

    return rules
  }

  private static user(getterName: string) {
    return store.getters[`user/${getterName}`]
  }

  // Права на просмотр страниц (Router)
  public static pages() {
    const pageList = PermissionPageManager._pages()
    return defineAbility(can => {
      for (const page of Object.keys(pageList)) {
        can(permissions.viewPage, page)
      }
    })
  }

  private static removeRule(name: string, rules: any): void {
    if (name in rules) {
      delete rules[name]
    }
  }

  private static addRule(name: string, rules: any): void {
    rules[name] = true
  }
}
