import {
  Actions,
  createMapper,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module'
import * as Sentry from '@sentry/vue'
import { Vue } from 'vue-property-decorator'
import _ from 'lodash'

import UserAPI, { User } from '@/api/user'
import InfoAPI, { BuildInfo } from '@/api/info'
import { roles } from '@/config/roles'
import { http } from '@/api/httpAxios'
import { ConfigLocalStorage } from '@/config/localstorage'
import moment from 'moment'

class UserState {
  user?: User | null
  clientVersion: BuildInfo | null = null
  isBadLogin = false

  jwtToken: any | null = null
  isBadToken = false
}

class UserGetters extends Getters<UserState> {
  get isAuthenticated() {
    return !_.isNil(this.state.user)
  }

  get currentUser() {
    return this.state.user
  }

  get userRoles() {
    return this.state.user?.roles ?? []
  }

  get userDepartments() {
    return this.state.user?.aisDepartments ?? []
  }

  get studentQualification() {
    if (this.getters.isStudent) {
      const currentEducation = this.getters.educations?.map((o: any) => {
        if (moment(new Date()).isBetween(o.startDate, o.endDate)) {
          return o.group
        }
      })
      return !_.isNil(currentEducation[0])
        ? currentEducation[0]!.specialty.qualification
        : ''
    }
    return null
  }

  get lkDepartments() {
    return this.state.user?.userDepartments ?? []
  }

  get educations() {
    return this.state.user?.educations ?? []
  }

  get partnerPosition() {
    return this.state.user?.companyLink
  }

  get isAdmin() {
    return this.getters.userRoles.includes(roles.admin)
  }

  get isStudent() {
    return this.getters.userRoles.includes(roles.student)
  }

  get isOnlyPartner() {
    return (
      this.getters.userRoles.includes(roles.partner) &&
      !this.getters.userRoles.includes(roles.PRManager)
    )
  }

  get isTeacher() {
    return this.getters.userRoles.includes(roles.teacher)
  }

  get isPartner() {
    return this.getters.userRoles.includes(roles.partner)
  }

  get allPartnerCompanies() {
    return this.state.user?.companies ?? []
  }

  get isMultiPartner() {
    return this.getters.allPartnerCompanies.length > 1
  }

  get isPRManager() {
    return this.getters.userRoles.includes(roles.PRManager)
  }

  get isDirector() {
    if (this.state.user && this.state.user.userDepartments) {
      for (const department of this.state.user?.userDepartments) {
        const isDirector = !!department.workFunctions.find(
          (o: any) => o.title === 'Заведующий кафедрой'
        )
        if (isDirector) {
          return isDirector
        }
      }
    }
    return false
  }

  get userDepHeadDepartment() {
    if (this.getters.isDirector) {
      for (const department of this.getters.currentUser?.userDepartments ??
        []) {
        const dep = department.workFunctions.find(
          (o: any) => o.title === 'Заведующий кафедрой' // убрали декана, так как некорректно хранится его подразделение
        )
        if (!_.isNil(dep)) {
          return department.workDepartment
        }
      }
    }
    return null
  }

  get isDepHeaded() {
    return !!this.state.user?.depHeaded
  }

  get isAssignedLeaderOfAnyDep() {
    const requiredRight = 'coordinateDepartment'
    const isAssignedDepHead = this.state.user?.aisDepartments?.find(
      (d: any) =>
        d?.UserAisDepartment?.departmentOpopRights?.includes(requiredRight) &&
        d?.UserAisDepartment?.departmentRpdRights?.includes(requiredRight)
    )
    return !!this.state.user?.isDepHeadRights || !!isAssignedDepHead
  }

  get isBannedUser() {
    return !!this.state.user?.bannedAt ?? false
  }

  get hasEducations() {
    return !_.isEmpty(this.state.user?.educations)
  }

  get partnerPermissions() {
    return this.state.user?.companyLink?.company?.permissions ?? []
  }

  get banReason() {
    const isBanned = this.getters.isBannedUser
    if (isBanned) {
      return this.state.user?.banReason ?? null
    }
    return null
  }

  get banDate() {
    return this.state.user?.bannedAt ?? null
  }

  get isFakeAuth() {
    return !!this.state.user?.actualUser
  }

  get userCompany() {
    return (
      this.state.user?.companyLink || {
        position: '',
        company: { id: 0, title: '', url: '' }
      }
    )
  }

  get hasCuratedProjects() {
    return this.state.user?.hasCuratedProjects
  }
}

class UserMutations extends Mutations<UserState> {
  setUser(user: User | null) {
    Vue.set(this.state, 'user', user)
    if (user) {
      Sentry.setUser({
        email: user.email,
        id: String(user.id),
        name: user.name,
        surname: user.surname,
        midname: user.midname
      })
    } else {
      Sentry.setUser(null)
    }
  }

  setClientVersion(data: BuildInfo | null) {
    this.state.clientVersion = data
  }
  setBadLogin(isBadLogin: boolean) {
    Vue.set(this.state, 'isBadLogin', isBadLogin)
  }
  setJWT(token: any) {
    Vue.set(this.state, 'jwtToken', token)
    http.defaults.headers['Authorization'] =
      token.tokenType + ' ' + token.accessToken
  }
  setBadToken(b: boolean) {
    Vue.set(this.state, 'isBadToken', b)
  }
}

class UserActions extends Actions<
  UserState,
  UserGetters,
  UserMutations,
  UserActions
> {
  async fetchCurrentUser(reload = false) {
    try {
      const [user, client] = await Promise.all([
        UserAPI.getCurrentUser(reload),
        InfoAPI.getClientBuildFromServer()
      ])
      this.mutations.setUser(user.data.user)
      this.mutations.setClientVersion(client.data)
      this.mutations.setBadToken(false)
      const redirect = localStorage.getItem(ConfigLocalStorage.redirect)
      if (redirect && user.data.user) {
        window.location.href = redirect
        localStorage.removeItem(ConfigLocalStorage.redirect)
      }
    } catch (err) {
      const error = err.response?.data?.error
      if (error === 'invalid_token' || error === 'invalid_request') {
        this.mutations.setBadToken(true)
      }
      this.mutations.setUser(null)
    }
  }
}

export const user = new Module({
  state: UserState,
  getters: UserGetters,
  mutations: UserMutations,
  actions: UserActions
})

export const userMappers = createMapper(user)
