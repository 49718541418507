import { baseURL, clientBase } from '@/api/httpAxios'
import { URLs, Contacts, systemNames } from '@orpk/orpk-vue-ts'

export const productionURL = URLs.productionURL
export const devProductionURL = URLs.devProductionURL

export const supportEmail = Contacts.supportEmail
export const wikiURL = Contacts.wikiURL

export const wikiPage = Contacts.wikiPage

export const lkETUName = systemNames.lk.auth

export const isDev = process.env.NODE_ENV !== 'production'
export const isProduction = !isDev

export const authBase = `${baseURL}/auth`
export const authDevBase = `${baseURL}/auth/dev`

export const clientBaseWithSlash = clientBase + '/'

export const vueDevToolsURL = URLs.vueDevToolsURL
