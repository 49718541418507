import {
  Getters,
  Mutations,
  Actions,
  createMapper,
  Module
} from 'vuex-smart-module'
import PartnersAPI from '@/api/partners.ts'
import _ from 'lodash'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-programs/helpers/requests'

export interface EducationalProgramSpecialties {
  id: number
  faculty: string
  department: string
  specialties: string
  specialtiesNumber: string
}

export interface InterestsPartner {
  id?: number
  title: string
  url: string
  info: string
}

export interface PartnerSendSpecialties {
  specialtyIds: number[]
}

export interface PartnerSendSubjects {
  planStringIds: number[]
}

export interface PartnerStandartForEventTable {
  id?: string
  title: string
}

export interface PartnerEventStages {
  stage: string
  period?: string
  responsibleUser?: string
}

export interface PartnerEventEstimate {
  article: string
  amount: string | number
  source: string
}

export interface PartnerEventData {
  id?: number
  status?: string
  meOrganizer?: any
  myParticipation?: any
  companyEventTypeId?: number
  reviewerComment?: string
  companyTitle?: string
  title: string
  comment: string
  objective: string
  tasks: string
  organizers?: PartnerStandartForEventTable[]
  isInPlan?: boolean
  eventLocation: string
  eventType: any
  eventStart: string
  eventEnd: string
  criteria: string
  awards: string
  coverage: string
  orderFilePath?: string
  regulationFilePath?: string
  estimate: []
  stages: PartnerEventStages[]
}

export interface EventsPartnerObject {
  eventList: PartnerEventData[]
}

export interface MainPageInfo {
  fieldOfActivity: string[]
  employeesNum: string | null
}

export const clearObjModal: PartnerEventData = {
  title: '',
  comment: '',
  companyTitle: '',
  objective: '',
  tasks: '',
  organizers: [],
  eventLocation: '',
  eventType: '',
  eventStart: '',
  eventEnd: '',
  criteria: '',
  awards: '',
  coverage: '',
  orderFilePath: '',
  regulationFilePath: '',
  stages: [],
  estimate: []
}

class PartnerState {
  idPartner = 1
  eduationProgram: any = {
    specialties: [],
    disciplines: []
  }
  participationEvents: any = {
    events: []
  }
  modalInfo = {
    name: '',
    source: '',
    description: '',
    id: 0
  }
  modalAddEventTable: PartnerEventData = _.cloneDeep(clearObjModal)
  events: EventsPartnerObject = {
    eventList: []
  }
}

class PartnerGetters extends Getters<PartnerState> {}

class PartnerMutations extends Mutations<PartnerState> {
  addItemToEventArray() {
    this.state.events.eventList = this.state.events.eventList.concat([
      this.state.modalAddEventTable
    ])
  }

  setItemToEventArray(index: number) {
    this.state.events.eventList[index] = this.state.modalAddEventTable
  }

  deleteItemToEventArray(indexDel: number) {
    this.state.events.eventList = this.state.events.eventList.filter(
      (i: any, index: any) => index !== indexDel
    )
  }

  clearModalAddEventTable() {
    this.state.modalAddEventTable = _.cloneDeep(clearObjModal)
  }

  setModalAddEventTable(eventObj: PartnerEventData) {
    this.state.modalAddEventTable = eventObj
  }

  setModalInfo(modalInfo: any) {
    this.state.modalInfo = modalInfo
  }

  setArrSpecialties(specialtiesArr: any[]) {
    this.state.eduationProgram.specialties = []
    for (const item of specialtiesArr) {
      this.state.eduationProgram.specialties = this.state.eduationProgram.specialties.concat(
        {
          faculty: item.faculty ? item.faculty.shortTitle : '',
          department: item.department ? item.department.shortTitle : '',
          specialty: item.title,
          cipher: item.cipher,
          idSpecialties: item.id
        }
      )
    }
  }

  setArrSubject(subjects: any[]) {
    this.state.eduationProgram.disciplines = []
    for (const item of subjects) {
      this.state.eduationProgram.disciplines = this.state.eduationProgram.disciplines.concat(
        {
          specialty: item.plan.specialty ? item.plan.specialty.title : '',
          cipher: item.plan.specialty ? item.plan.specialty.cipher : '',
          discipline: item.subject,
          fileName: item.plan.fileName ? item.plan.fileName : '',
          descriptionAccent: item.CompanySubject.info
            ? item.CompanySubject.info
            : '',
          planStringId: item.id,
          idSpecialties: item.plan.specialty ? item.plan.specialty.id : null
        }
      )
    }
  }

  setIdPartner(id: number) {
    this.state.idPartner = id
  }

  setArrInterests(interests: any[]) {
    this.state.participationEvents.events = []
    for (const item of interests) {
      this.state.participationEvents.events = this.state.participationEvents.events.concat(
        {
          name: item.title ? item.title : '',
          source: item.url ? item.url : '',
          description: item.info ? item.info : '',
          id: item.id ? item.id : ''
        }
      )
    }
  }
}

class PartnerActions extends Actions<
  PartnerState,
  PartnerGetters,
  PartnerMutations,
  PartnerActions
> {
  async fetchSpecialitiesPartner() {
    try {
      const response = await PartnerEducationProgramsAPI.getSpecialtiesPartner(
        this.state.idPartner
      )
      this.mutations.setArrSpecialties(response.data)
    } catch (err) {
      console.error(err)
    }
  }
  async fetchSubjectPartner() {
    try {
      const response = await PartnersAPI.getSubjectsPartner(
        this.state.idPartner
      )
      this.mutations.setArrSubject(response.data)
    } catch (err) {
      console.error(err)
    }
  }
  async fetchInterestsPartner() {
    try {
      const response = await PartnersAPI.getInterestsPartner(
        this.state.idPartner
      )
      this.mutations.setArrInterests(response.data)
    } catch (err) {
      console.error(err)
    }
  }
}

export const partner = new Module({
  state: PartnerState,
  getters: PartnerGetters,
  mutations: PartnerMutations,
  actions: PartnerActions
})

export const partnerMapper = createMapper(partner)
