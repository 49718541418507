import { Vue, Component } from 'vue-property-decorator'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import Config from '@/config/config.ts'

const Mappers = Vue.extend({
  methods: {
    ...mobileWidthMapper.mapMutations(['setWindowWidth', 'setWindowHeight'])
  }
})

@Component
export default class MobileWidthMixin extends Mappers {
  private created() {
    this.setWindowWidth(window?.innerWidth ?? Config.defaultWidth)
    window.addEventListener('resize', this.windowEventHandler)
  }
  private destroyed() {
    window.removeEventListener('resize', this.windowEventHandler)
  }
  private windowEventHandler() {
    this.$nextTick(() => {
      this.setWindowWidth(window?.innerWidth ?? Config.defaultWidth)
      this.setWindowHeight(window?.innerHeight ?? Config.defaultHeight)
    })
  }
}
