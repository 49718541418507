import { defineAbility } from '@casl/ability'
import store from '@/store'
import {
  permissions,
  permissionsTranslation
} from '@/api/permissions/permissions'

export class PermissionManager {
  // Права на дополнительные кнопки
  public static common() {
    return defineAbility(can => {
      if (this.user('isAdmin')) {
        can(permissions.adminSystem, undefined)
      }
      if (this.user('isStudent')) {
        if (!this.user('isBannedUser') && this.user('hasEducations')) {
          can(permissions.chatWithPartners, undefined)
        }
      }

      if (this.user('isPartner')) {
        can(permissions.editOrganisationPage, undefined)
        if (this.user('isPremiumPartner')) {
          can(permissions.chatWithStudents, undefined)
        }
      }
      if (this.user('isPRManager')) {
        can(permissions.invitePartners, undefined)
        can(permissions.createOrganisations, undefined)
        can(permissions.banUser, undefined)
        can(permissions.viewStatistics, undefined)
      }
      if (this.user('isAssignedLeaderOfAnyDep')) {
        can(permissions.deputyDepHead, undefined)
      }
    })
  }

  public static user(getterName: string) {
    return store.getters[`user/${getterName}`]
  }

  public static translateUserDepartmentDocumentAbility(abilities: any) {
    const result: { [k: string]: string[] } = {}
    for (const pageName of abilities.rules) {
      const perm = permissionsTranslation[pageName.action]
      const title = pageName.subject
      if (title in result) {
        result[title].push(perm)
      } else {
        result[title] = [perm]
      }
    }
    return result
  }

  public static translateAbility(abilities: any) {
    const result = []
    for (const pageName of abilities.rules) {
      result.push(permissionsTranslation[pageName.action])
    }
    return [...new Set(result)]
  }
}
