import { http } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'
import { Log } from '@/store/modules/logger'

export default class LoggerAPI {
  public static sendLogs(logs: Log[]): AxiosPromise {
    return http.post('/general/logging/client', logs)
  }

  public static getApiLogs(
    fileName = 0,
    type: 'info' | 'error' = 'info'
  ): AxiosPromise {
    const params = new URLSearchParams()
    params.append('fileName', String(fileName))
    return http.get(`/general/logging/server/${type}`, { params })
  }
}
