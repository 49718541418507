import Config from '@/config/config'
import { ifAuthenticated } from '@/router/before'

export const authRouteNames = {
  AuthMainRoute: 'AuthMainRoute',
  AuthPartnerRoute: 'AuthPartnerRoute',
  RegistrationPartnerRoute: 'RegistrationPartnerRoute',
  PasswordRecoveryRoute: 'PasswordRecoveryRoute',
  LogoutRoute: 'LogoutRoute'
}
const rn = authRouteNames

export const authRouteURLs = {
  [rn.AuthMainRoute]: Config.routing.authPage.path,
  [rn.AuthPartnerRoute]: '/partner/auth',
  [rn.RegistrationPartnerRoute]: '/partner/registration',
  [rn.PasswordRecoveryRoute]: '/partner/password-recovery',
  [rn.LogoutRoute]: '/logout'
}
export const authPages = [
  {
    path: authRouteURLs[rn.AuthMainRoute],
    name: rn.AuthMainRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: Config.systemTitle.fullName,
        displayName: Config.routing.authPage.name
      },
      breadcrumbs: null
    },
    component: () =>
      import(
        /* webpackChunkName: "AuthMainRoute" */ '@/views/auth/AuthMainRoute.vue'
      )
  },
  {
    path: authRouteURLs[rn.AuthPartnerRoute],
    name: rn.AuthPartnerRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: Config.systemTitle.fullName,
        displayName: 'Авторизация партнера'
      },
      breadcrumbs: null
    },
    component: () =>
      import(
        /* webpackChunkName: "AuthPartnerRoute" */ '@/views/auth/AuthPartnerRoute.vue'
      )
  },
  {
    path: authRouteURLs[rn.RegistrationPartnerRoute],
    name: rn.RegistrationPartnerRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: Config.systemTitle.fullName,
        displayName: 'Регистрация'
      },
      breadcrumbs: null
    },
    component: () =>
      import(
        /* webpackChunkName: "RegistrationPartnerRoute" */ '@/views/auth/RegistrationPartnerRoute.vue'
      )
  },
  {
    path: authRouteURLs[rn.PasswordRecoveryRoute],
    name: rn.PasswordRecoveryRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: Config.systemTitle.fullName,
        displayName: 'Восстановление пароля'
      },
      breadcrumbs: null
    },
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecoveryRoute" */ '@/views/auth/PasswordRecoveryRoute.vue'
      )
  },
  {
    path: authRouteURLs[rn.LogoutRoute],
    name: rn.LogoutRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: Config.systemTitle.fullName,
        displayName: 'Выход'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "LogoutRoute" */ '@/views/auth/LogoutRoute.vue'
      )
  }
]
