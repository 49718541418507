















































import { Component, Vue, Watch } from 'vue-property-decorator'

import { userMappers } from '@/store/modules/user'
import { RouteConfig } from 'vue-router/types/router'
import { routes } from '@/router'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import { PermissionPageManager } from '@/api/permissions/pages'
import { permissions } from '@/api/permissions/permissions'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import { routeBuild } from '@/utils/urls'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile']),
    ...userMappers.mapGetters([
      'isAuthenticated',
      'isPartner',
      'userCompany',
      'isPRManager'
    ])
  }
})
@Component({ mixins: [ResolveCompanyId] })
export default class Breadcrumbs extends Mappers {
  private pages: RouteConfig[] = []

  getCompanyId!: Function
  companyIdToURL!: Function

  private forceHideBreadCrumb = false

  private isBreadCrumb = true

  private hideBreadCrumb() {
    this.isBreadCrumb = !this.isBreadCrumb
  }

  private isMatchURL(route: any) {
    return route.name === this.$route.name ? 'active' : ''
  }

  private goTo(path: string) {
    this.$router
      .push(
        routeBuild(path, {
          partnerId: this.companyIdToURL(this.getCompanyId())
        })
      )
      .catch(() => {
        return undefined
      })
  }

  @Watch('$route')
  private onRouteChange() {
    this.forceHideBreadCrumb =
      this.isPartner &&
      this.getCompanyId() !== this.userCompany.company.id &&
      !this.isPRManager

    const routerPages: RouteConfig[] | undefined = routes[0].children
    const ability = PermissionPageManager.pages()
    this.pages = (routerPages ?? []).filter(
      p =>
        this.$route.meta?.breadcrumbs?.isDisplay &&
        p?.meta?.breadcrumbs?.isDisplay &&
        ability.can(permissions.viewPage, p.name)
    )
  }

  private created() {
    this.onRouteChange()
  }
}
