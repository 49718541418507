import { ifAuthenticated } from '@/router/before'

export const projectRouteNames = {
  ProjectListRoute: 'ProjectListRoute',
  ProjectAddRoute: 'ProjectAddRoute',
  ProjectViewRoute: 'ProjectViewRoute',
  ProjectCardsRoute: 'ProjectCardsRoute',
  ProjectDepHeadManagementRoute: 'ProjectDepHeadManagementRoute',
  ProjectMyRoute: 'ProjectMyRoute',
  ProjectStudentsRoute: 'ProjectStudentsRoute'
}
const rn = projectRouteNames

export const projectRouteURLs = {
  [rn.ProjectListRoute]: '/partner/:partnerId/projects',
  [rn.ProjectAddRoute]: '/partner/:partnerId/projects/add',
  [rn.ProjectViewRoute]: '/partner/:partnerId/projects/:projectId',
  [rn.ProjectCardsRoute]: '/projects',
  [rn.ProjectDepHeadManagementRoute]: '/projects/dephead-management',
  [rn.ProjectMyRoute]: '/projects/my',
  [rn.ProjectStudentsRoute]: '/projects/students'
}

export const projectPages = [
  {
    // Страница для партнера для просмотра списка проектов
    path: projectRouteURLs[rn.ProjectListRoute],
    name: rn.ProjectListRoute,
    meta: {
      sidebar: {
        isDisplay: true,
        icon: ['fas', 'tasks'],
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Проекты',
        displayName: 'Проекты организации'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Проекты'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProjectListRoute" */ '@/views/projects/ProjectListRoute.vue'
      )
  },
  {
    // Страница для партнера для добавления проекта
    path: projectRouteURLs[rn.ProjectAddRoute],
    name: rn.ProjectAddRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Проекты',
        displayName: 'Создание проекта'
      },
      isDisplayInBreadCrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProjectAddRoute" */ '@/views/projects/ProjectAddRoute.vue'
      )
  },
  {
    // Страница для партнера, куратора для редактирования/просмотра проекта (вехи, кураторы, канбан)
    path: projectRouteURLs[rn.ProjectViewRoute],
    name: rn.ProjectViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Проекты',
        displayName: ''
      },
      isDisplayInBreadCrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProjectViewRoute" */ '@/views/projects/ProjectViewRoute.vue'
      )
  },
  {
    // Страница для всех со списком всех проектов
    path: projectRouteURLs[rn.ProjectCardsRoute],
    name: rn.ProjectCardsRoute,
    meta: {
      sidebar: {
        isDisplay: true,
        icon: ['fas', 'tasks']
      },
      navbar: {
        group: 'Проекты',
        displayName: 'Все проекты'
      },
      isDisplayInBreadCrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProjectCardsRoute" */ '@/views/projects/ProjectCardsRoute.vue'
      )
  },
  {
    // Страница студентов участников проекта
    path: projectRouteURLs[rn.ProjectStudentsRoute],
    name: rn.ProjectStudentsRoute,
    meta: {
      sidebar: {
        isDisplay: true,
        icon: ['fas', 'tasks']
      },
      navbar: {
        group: 'Проекты',
        displayName: 'Cтуденты'
      },
      isDisplayInBreadCrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ProjectCardsRoute" */ '@/views/projects/ProjectStudentsRoute.vue'
      )
  }
]
