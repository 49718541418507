import moment from 'moment'
import { baseURL } from '@/api/httpAxios'
import _ from 'lodash'
import { defaultLogo, defaultNewsAvatar } from '@/config/defaultImages'
import dateFormat from '@/config/dateFormat'
import { uploadFiles } from '@/config/uploadFiles'
import { studyLevelText } from '@/config/study/studyLevel'
import { vacancyStatus } from '@/config/vacancies/vacancies'
import { adaptDateToClient, adaptDateToServer } from '@/utils/fieldValidation'

function getLikeClicked(like?: boolean) {
  return !_.isNil(like) ? (like ? 'like' : 'dislike') : undefined
}

export class PartnersAdapter {
  public static getOneNewsForClient(news: any) {
    news.clicked = getLikeClicked(news.userLike)

    if (!_.isString(news.avatar)) {
      news.avatar = news.avatar?.path
        ? baseURL + news.avatar.path
        : defaultNewsAvatar
    }

    if (!_.isString(news.croppedAvatar)) {
      news.croppedAvatar = news.croppedAvatar?.path
        ? baseURL + news.croppedAvatar.path
        : defaultNewsAvatar
    }
    return news
  }
  public static getOneNewsForServer(news: any) {
    news.title = news.title || ''
    return news
  }
  public static getNews(newsList: any[]) {
    return newsList.map(news => PartnersAdapter.getOneNewsForClient(news))
  }
  public static getPartnersList(partners: any[]) {
    for (const partner of partners) {
      partner.likes = Number(partner.likes || 0)
      partner.likeClicked = getLikeClicked(partner.userLike)
      partner.dislikes = Number(partner.dislikes || 0)
      const croppedLogo = partner.files.find(
        (file: any) => file.type === uploadFiles.croppedlogo
      )
      partner.avatar = croppedLogo ? baseURL + croppedLogo.path : defaultLogo
    }
    return partners
  }
  public static getPartner(partner: any) {
    partner.likeClicked = getLikeClicked(partner.userLike)
    for (let i = 0; i < partner.files.length; i++) {
      partner.files[i].path = baseURL + partner.files[i].path
      partner.files[i].updatedAt = moment(partner.files[i].updatedAt).format(
        dateFormat.date.dateTime
      )
    }

    const logoFile = partner.files.find((file: any) => file.type === 'logo')
    partner.avatar = logoFile?.path ?? defaultLogo

    const croppedLogoFile = partner.files.find(
      (file: any) => file.type === uploadFiles.croppedlogo
    )
    if (_.isNil(croppedLogoFile)) {
      partner.avatarCropped = defaultLogo
    } else {
      partner.avatarCropped = croppedLogoFile.path
    }

    partner.gallery = partner.files.filter(
      (file: any) => file.type === uploadFiles.gallery
    )
    partner.presentation = partner.files.filter(
      (file: any) => file.type === uploadFiles.presentation
    )
    return partner
  }

  public static castModelAdd(model: any) {
    return {
      title: model.title,
      startDate: PartnersAdapter.castDate(model.startDate),
      endDate: PartnersAdapter.castDate(model.endDate),
      id: model.id
    }
  }

  public static castDate(date: any) {
    return moment(date, dateFormat.date.localFormat).format(
      dateFormat.date.serverSimpleFormat
    )
  }
  public static reverseCastDate(date: any): string {
    return moment(date, dateFormat.date.serverSimpleFormat).format(
      dateFormat.date.localFormat
    )
  }

  public static getEducation(group: any) {
    let res = ''
    if (!group) {
      return res
    }

    if (group.course < Number(group.educationTime)) {
      res += 'Студент'
      const studyLevel: string = group.plan?.studyLevel.title
      const studyLevelText_ = (studyLevelText as any)[studyLevel]
      if (studyLevelText_) {
        res += ' ' + studyLevelText_
      }
    }
    return res
  }

  public static getProfiles(data: any) {
    return data.map((row: any) => ({
      id: row.id,
      position: row.data?.desiredPosition,
      initials: row.initials,
      education: this.getEducation(
        !_.isEmpty(row.educations) ? row.educations[0].group : null
      ),
      direction: !_.isEmpty(row.educations)
        ? row.educations[0].group.specialty.title
        : '',
      // TODO chelent0ss получение возраста и пола с сервера
      age: row.age ?? '',
      gender: row.gender ?? ''
    }))
  }

  public static castProjectToClient(model: any) {
    return {
      ...model,
      filesInner: model.projectFiles.map((f: any) => {
        if (f.file) {
          return { ...f, ...f.file, path: baseURL + f.file.path }
        } else {
          return { ...f, path: f.link }
        }
      })
    }
  }

  public static castRequisites(data: any[]) {
    return (data ?? [])
      .map((p: any) => p.title)
      .filter((p: string | null) => !_.isNil(p))
  }

  public static castProjectToServer(model: any) {
    return {
      title: model.title,
      requiredStudentsCount: model.requiredStudentsCount,
      projectTypeId: model.type?.id || null,
      info: model.info,
      id: model.id,
      prerequisitesKnowledge: PartnersAdapter.castRequisites(
        model.prerequisitesKnowledge
      ),
      prerequisitesAbilities: PartnersAdapter.castRequisites(
        model.prerequisitesAbilities
      ),
      prerequisitesSkills: PartnersAdapter.castRequisites(
        model.prerequisitesSkills
      ),
      postrequisitesKnowledge: PartnersAdapter.castRequisites(
        model.postrequisitesKnowledge
      ),
      postrequisitesAbilities: PartnersAdapter.castRequisites(
        model.postrequisitesAbilities
      ),
      postrequisitesSkills: PartnersAdapter.castRequisites(
        model.postrequisitesSkills
      )
    }
  }

  public static castStudentProjectToServer(model: any) {
    return {
      studentId: model.user.id,
      role: model.role,
      additionalKnowledge: (model.additionalKnowledge ?? []).map(
        (p: any) => p.title
      ),
      additionalAbilities: (model.additionalAbilities ?? []).map(
        (p: any) => p.title
      ),
      additionalSkills: (model.additionalSkills ?? []).map((p: any) => p.title)
    }
  }

  public static castMaterialsToClient(materials: any[]) {
    return materials.map((material: any) => {
      const doc = new DOMParser().parseFromString(
        material.file ? material.file.info : material.info,
        'text/html'
      )
      const type = material.file ? 'Файл' : 'Ссылка'
      return {
        id: material.id,
        type,
        title: material.file ? material.file.title : material.title,
        info: doc.body.textContent || '',
        path: material.file ? baseURL + material.file.path : material.link,
        updatedAt: material.file ? material.file.updatedAt : material.updatedAt
      }
    })
  }

  public static getEventsPartner(rawRows: any[]) {
    return rawRows.map((row: any) => ({
      ...row,
      eventStart: row.eventStart ?? '',
      eventEnd: row.eventEnd ?? '',
      companyTitle: row.companyTitle ?? ''
    }))
  }

  public static getVacanciesPartner(rawRows: any[]) {
    const rows = []
    for (const row of rawRows) {
      rows.push(
        row.status === vacancyStatus.publicated
          ? {
              ...row,
              _noConfirm: true
            }
          : row
      )
    }
    return rows
  }

  public static castDataForUpdatePartner(data: any) {
    return {
      address: data.address,
      url: _.isEmpty(data.url) ? null : data.url,
      fieldOfActivity: data.fieldOfActivity,
      employeesNum: data.employeesNum,
      info: data.info
    }
  }

  public static castDataForAddPartner(data: any) {
    const objSend = _.cloneDeep(data)
    objSend.eventStart = adaptDateToServer(objSend.eventStart)
    objSend.eventEnd = adaptDateToServer(objSend.eventEnd)
    objSend.organizerId = objSend.organizer?.id
    if (!_.isEmpty(objSend.eventType)) {
      objSend.companyEventTypeId = objSend.eventType.id
    }
    return objSend
  }

  public static castDataForEditEvent(data: any, eventTypes: any[]) {
    const dataEvent = _.cloneDeep(data)
    dataEvent.eventType = dataEvent.companyEventTypeId
      ? eventTypes.find((el: any) => el.id === dataEvent.companyEventTypeId) ??
        ''
      : ''
    dataEvent.eventStart = adaptDateToClient(dataEvent.eventStart)
    dataEvent.eventEnd = adaptDateToClient(dataEvent.eventEnd)
    return dataEvent
  }

  public static claimsToClient(data: any) {
    return data.map((e: any) => ({
      ...e,
      isActive: !e.bannedAt
    }))
  }

  public static castProjectMembersToClient(data: any[], projectId: number) {
    return data.map((member: any) => {
      const project = member.projects.find(
        (project: any) => project.id === projectId
      )
      const group = !_.isEmpty(member.educations)
        ? member.educations[0].group
        : null
      const doc = new DOMParser().parseFromString(
        project.ProjectStudent.comment || '',
        'text/html'
      )
      return {
        avatar: member.avatar,
        email: member.email,
        id: member.id,
        initials: member.initials,
        status: project.ProjectStudent.status,
        role: project.ProjectStudent.role,
        mark: project.ProjectStudent.mark,
        comment: doc.body.textContent || '',
        group: group ? group.name : '',
        motivationLetter: project.ProjectStudent.motivationLetter,
        department: group ? group.plan.department.shortTitle : '',
        updatedAt: project.ProjectStudent.updatedAt
      }
    })
  }
}
