import { ifAuthenticated } from '@/router/before'

export const emManagerRouteNames = {
  EmManagerPartnersRoute: 'EmManagerPartnersRoute',
  EmManagerComplaintRoute: 'EmManagerComplaintRoute',
  EmManagerUserDeleteRoute: 'EmManagerUserDeleteRoute',
  EmManagerHistoryRoute: 'EmManagerHistoryRoute',
  EmManagerStatisticsRoute: 'EmManagerStatisticsRoute',
  EmManagerDictsRoute: 'EmManagerDictsRoute',
  EmManagerOrderPriorityRoute: 'EmManagerOrderPriorityRoute'
}
const rn = emManagerRouteNames

export const emManagerURLs = {
  [rn.EmManagerPartnersRoute]: '/em-manager/partners',
  [rn.EmManagerUserDeleteRoute]: '/em-manager/user-delete',
  [rn.EmManagerComplaintRoute]: '/em-manager/complaint',
  [rn.EmManagerHistoryRoute]: '/em-manager/history',
  [rn.EmManagerStatisticsRoute]: '/em-manager/statistics',
  [rn.EmManagerDictsRoute]: '/em-manager/dicts',
  [rn.EmManagerOrderPriorityRoute]: '/em-manager/content-sort'
}

export const emManagerPages = [
  {
    path: emManagerURLs[rn.EmManagerPartnersRoute],
    name: rn.EmManagerPartnersRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'tasks'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Список партнеров'
      },
      breadcrumbs: false
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerPartnersRoute" */ '@/views/em-manager/EmManagerPartnersRoute.vue'
      )
  },
  {
    path: emManagerURLs[rn.EmManagerUserDeleteRoute],
    name: rn.EmManagerUserDeleteRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'user-slash'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Заявки на удаление сотрудников'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerUserDeleteRoute" */ '@/views/em-manager/EmManagerUserDeleteRoute.vue'
      )
  },
  {
    path: emManagerURLs[rn.EmManagerComplaintRoute],
    name: rn.EmManagerComplaintRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'th-list'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Блокировка'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerComplaintRoute" */ '@/views/em-manager/EmManagerComplaintRoute.vue'
      )
  },

  {
    path: emManagerURLs[rn.EmManagerHistoryRoute],
    name: rn.EmManagerHistoryRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'history'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'История изменений'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerHistoryRoute" */ '@/views/em-manager/EmManagerHistoryRoute.vue'
      )
  },

  {
    path: emManagerURLs[rn.EmManagerStatisticsRoute],
    name: rn.EmManagerStatisticsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'chart-bar'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Статистика партнеров'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerStatisticsRoute" */ '@/views/em-manager/EmManagerStatisticsRoute.vue'
      )
  },

  {
    path: emManagerURLs[rn.EmManagerDictsRoute],
    name: rn.EmManagerDictsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'book'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Настройки'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerDictsRoute" */ '@/views/em-manager/EmManagerDictsRoute.vue'
      )
  },

  {
    path: emManagerURLs[rn.EmManagerOrderPriorityRoute],
    name: rn.EmManagerOrderPriorityRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'angle-double-up'],
        isDisplay: true
      },
      navbar: {
        group: 'Управление партнерами',
        displayName: 'Порядок сортировки'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "EmManagerOrderPriorityRoute" */ '@/views/em-manager/EmManagerOrderPriorityRoute.vue'
      )
  }
]
