



























import { Component, Vue } from 'vue-property-decorator'
import Profile from '@/components/pages/home/components/sidebar/components/Profile.vue'
import { routes } from '@/router'
import { RouteConfig, RouteConfigSingleView } from 'vue-router/types/router'
import { userMappers } from '@/store/modules/user'
import Config from '@/config/config.ts'
import Organisation from '@/components/pages/home/components/sidebar/components/Organisation.vue'
import { PermissionPageManager } from '@/api/permissions/pages'
import { permissions } from '@/api/permissions/permissions'
import { supportEmail, wikiPage } from '@/config/urls'
import RouteCard from '@/components/pages/home/components/sidebar/components/RouteCard.vue'
import FakeUserAPI from '@/components/routes/dev/fake-auth/helpers/requests'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['isFakeAuth', 'isPartner', 'isPRManager'])
  }
})

@Component({ components: { RouteCard, Profile, Organisation } })
export default class Sidebar extends Mappers {
  private treeSidebar: { [k: string]: RouteConfigSingleView[] } = {}
  private title = Config.systemTitle.shortName

  private routeSupport = {
    meta: {
      sidebar: {
        icon: ['fas', 'envelope']
      },
      navbar: {
        displayName:
          `<div>Поддержка: <a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a></div>` +
          `<div><a href="${wikiPage(
            'supportrules'
          )}">Правила написания</a></div>`
      }
    }
  }

  private routeExit = {
    click: this.onLogout,
    meta: {
      sidebar: {
        icon: ['fas', 'sign-out-alt']
      },
      navbar: {
        displayName: 'Выйти из режима просмотра'
      }
    }
  }

  private created() {
    const routerPages: RouteConfig[] | undefined = routes[0].children
    const abilities = PermissionPageManager.sidebar()
    this.treeSidebar = {}

    if (routerPages) {
      for (const route of routerPages) {
        if (
          abilities.can(permissions.viewPage, route.name) &&
          route?.meta?.sidebar?.isDisplay
        ) {
          const group = route.meta.navbar.group

          if (group in this.treeSidebar) {
            this.treeSidebar[group].push(route)
          } else {
            this.treeSidebar[group] = [route]
          }
        }
      }
    }
  }

  private onLogout() {
    FakeUserAPI.fakeUserLogout()
  }
}
