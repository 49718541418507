import {
  Getters,
  Mutations,
  Actions,
  Module,
  createMapper
} from 'vuex-smart-module'
import LoggerAPI from '@/components/routes/admin/server-logs/helpers/requests'

export interface Log {
  userId?: number | null
  severity: string
  message: string
  page: string
  error?: any | null
  timestamp: number
}

class LoggerState {
  logs: Log[] = []
}

class LoggerGetters extends Getters<LoggerState> {}

class LoggerMutations extends Mutations<LoggerState> {
  addLog(action: Log) {
    this.state.logs = this.state.logs.concat([action])
  }

  clearLogs() {
    this.state.logs = []
  }
}

class LoggerActions extends Actions<
  LoggerState,
  LoggerGetters,
  LoggerMutations,
  LoggerActions
> {
  async sendLogs() {
    if (this.state.logs.length) {
      try {
        await LoggerAPI.sendLogs(this.state.logs)
        this.mutations.clearLogs()
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export const logger = new Module({
  state: LoggerState,
  getters: LoggerGetters,
  mutations: LoggerMutations,
  actions: LoggerActions
})

export const loggerMapper = createMapper(logger)
