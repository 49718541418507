import { GridApi } from 'ag-grid-community'
import store from '@/store'
import validators from 'vue-form-generator'
import { Translation } from '@orpk/orpk-vue-ts'

export default class AgGridFactory {
  public static getDefaultGridOptions() {
    return {
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true,
        ...AgGridFactory.getDefaultFilterParams()
      },
      localeText: Translation.agGrid.localeTableText,
      suppressMenuHide: true,
      enableCellTextSelection: true,
      suppressDragLeaveHidesColumns: true,
      onGridReady: ({ api }: { api: GridApi }) => {
        this.onGridSizeChanged({ api })
      },
      onGridSizeChanged: this.onGridSizeChanged
    }
  }
  public static getActionColumn(params: any) {
    return {
      headerName: 'Действия',
      pinned: store.state['mobileWidth']['isMobile'] ? 'unset' : 'right',
      colId: 'actions',
      filter: false,
      sortable: false,
      cellRendererFramework: 'ActionRenderer',
      suppressSizeToFit: true,
      ...params
    }
  }
  public static getDefaultRowHeight() {
    return 45
  }
  public static getBigRowHeight(scale = 3) {
    return scale * AgGridFactory.getDefaultRowHeight()
  }
  public static getBigRowCellStyle() {
    return { cellStyle: { 'white-space': 'normal', 'line-height': '1.5' } }
  }
  public static onGridSizeChanged({ api }: { api: GridApi }) {
    if (!store.state['mobileWidth']['isMobile']) {
      api.sizeColumnsToFit()
    }
    api.resetRowHeights()
  }
  public static getDefaultFilterParams() {
    return {
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    }
  }

  public static getSimpleFilterParams() {
    return {
      filterParams: {
        buttons: ['reset'],
        filterOptions: ['contains']
      }
    }
  }

  public static tableAddField(options: any) {
    return {
      type: 'agGrid',
      required: true,
      buttonToolbar: true,
      wrapHeader: true,
      noCloneButton: true,
      validator: validators.array,
      columnDefs: [
        {
          headerName: 'Название',
          field: 'title',
          colId: 'title',
          autoHeight: true,
          cellStyle: { 'white-space': 'normal', 'line-height': 1.5 }
        }
      ],
      gridOptions: {
        ...AgGridFactory.getDefaultGridOptions(),
        defaultColDef: {
          editable: true,
          autoHeight: true,
          filter: false
        },
        domLayout: 'autoHeight'
      },
      ...options
    }
  }
}
