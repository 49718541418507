




























import { Component, Mixins, Watch } from 'vue-property-decorator'
import { abstractField } from 'vue-form-generator'
import { AgGridVue } from 'ag-grid-vue'
import _ from 'lodash'
import { GenField } from 'vue-form-generator'
import { GridApi, CellEvent } from 'ag-grid-community'
import { ICellRendererParams, ColDef } from 'ag-grid-community'
import ActionRenderer from '@/components/ag-grid/renderers/ActionRenderer.vue'

import { mobileWidthMapper } from '@/store/modules/mobileWidth'

const Mappers = Mixins(abstractField).extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({ components: { AgGridVue, ActionRenderer } })
export default class AgGridField extends Mappers {
  private schema!: GenField
  private value!: any[]
  private rowData!: any[]

  updated() {
    return undefined
  }

  private get gridApi(): GridApi {
    return this.schema.gridOptions.api
  }

  private get columnDefs(): ColDef[] {
    let colDefs = _.cloneDeep(this.schema.columnDefs)
    for (const column of colDefs) {
      column.editable =
        (!_.isNil(column.editable)
          ? column.editable
          : this.schema.gridOptions.defaultColDef.editable) &&
        !this.schema.disabled
    }

    if (this.schema.buttonToolbar && !this.schema.disabled) {
      colDefs.push({
        headerName: 'Действия',
        pinned: this.isMobile ? 'unset' : 'right',
        colId: 'actions',
        cellRendererFramework: 'ActionRenderer',
        maxWidth: this.schema.upDown ? 180 : 120,
        minWidth: 120,
        editable: false,
        filter: false,
        cellRendererParams: {
          onDelete: this.schema.noDelButton ? null : this.onDelete,
          onClone: this.schema.noCloneButton ? null : this.onClone,
          onUp: this.schema.upDown ? this.onUp : null,
          onDown: this.schema.upDown ? this.onDown : null,
          onEdit: !_.isNil(this.schema.editFunc) ? this.schema.editFunc : null,
          length: this.value?.length
        }
      })
    }
    if (this.schema.wrapHeader) {
      colDefs = colDefs.map((colDef: any) => ({
        ...colDef,
        headerClass: 'AgGridHeader-wrap'
      }))
    }
    return colDefs
  }

  private colDefs: ColDef[] = []

  @Watch('columnDefs')
  private onColDefChanged() {
    const colDefs = _.cloneDeep(this.columnDefs)
    if (this.gridApi && !_.isEqual(colDefs, this.colDefs)) {
      this.colDefs = colDefs
      this.gridApi.setColumnDefs(this.colDefs)
    }
  }

  private disableValueWatch = 0

  @Watch('value', { deep: true })
  private onValueChanged(value: any[]) {
    if (this.disableValueWatch > 0) {
      this.disableValueWatch--
    } else {
      this.rowData = _.cloneDeep(value)
      this.gridApi.setRowData(this.rowData)
    }
  }

  private get style() {
    if (this.schema.style) {
      return this.schema.style
    }

    if (this.schema.gridOptions.domLayout) {
      return {
        width: '100%'
      }
    }
    return {
      width: '100%',
      height: '400px'
    }
  }

  private onAdd() {
    const { add } = this.gridApi.applyTransaction({
      add: [{}]
    })
    this.rowData.push(...add.map(r => r.data))
    this.disableValueWatch++
    this.value = _.cloneDeep(this.rowData)
  }

  private onDelete(params: ICellRendererParams) {
    this.gridApi.applyTransaction({
      remove: [params.node.data]
    })
    this.rowData = []
    this.gridApi.forEachNode(node => {
      this.rowData.push(_.cloneDeep(node.data))
    })
    this.disableValueWatch++
    this.value = _.cloneDeep(this.rowData)
    this.$nextTick(() => {
      if (this.schema.deleteCallback) {
        this.schema.deleteCallback(this.gridApi)
      }
    })
  }

  private onClone(params: ICellRendererParams) {
    this.gridApi.applyTransaction({
      add: [_.cloneDeep(params.data)],
      addIndex: params.rowIndex
    })
    const rowData: any[] = []
    this.gridApi.forEachNode(node => rowData.push(node.data))
    this.rowData = rowData
    this.value = _.cloneDeep(rowData)
    this.$nextTick(() => {
      if (this.schema.cloneCallback) {
        this.schema.cloneCallback(this.gridApi)
      }
    })
  }

  private onUp(params: ICellRendererParams) {
    const prev = _.cloneDeep(this.rowData[params.rowIndex - 1])
    const curr = _.cloneDeep(this.rowData[params.rowIndex])
    this.rowData = this.rowData.map((v, i) => {
      if (i === params.rowIndex - 1) {
        return curr
      } else if (i === params.rowIndex) {
        return prev
      }
      return v
    })
    this.gridApi.setRowData(this.rowData)
    this.$nextTick(() => {
      if (this.schema.upCallback) {
        this.schema.upCallback(this.gridApi)
      }
    })
  }

  private onDown(params: ICellRendererParams) {
    const prev = _.cloneDeep(this.rowData[params.rowIndex + 1])
    const curr = _.cloneDeep(this.rowData[params.rowIndex])
    this.rowData = this.rowData.map((v, i) => {
      if (i === params.rowIndex + 1) {
        return curr
      } else if (i === params.rowIndex) {
        return prev
      }
      return v
    })
    this.gridApi.setRowData(this.rowData)
    this.$nextTick(() => {
      if (this.schema.downCallback) {
        this.schema.downCallback(this.gridApi)
      }
    })
  }

  private created() {
    if (this.schema.gridOptions) {
      if (!this.schema._onGridReadyInjected) {
        if (!this.schema.gridOptions.onGridReady) {
          this.schema.gridOptions.onGridReady = this.onGridReady
        } else {
          this.schema.gridOptions.onGridReady = this.makeGridReady(
            this.schema.gridOptions.onGridReady
          )
        }
        this.schema._onGridReadyInjected = true
      }
      if (!this.schema._onCellValueChangedInjected) {
        if (!this.schema.gridOptions.onCellValueChanged) {
          this.schema.gridOptions.onCellValueChanged = this.onCellValueChanged
        } else {
          this.schema.gridOptions.onCellValueChanged = this.makeCellValueChanged(
            this.schema.gridOptions.onCellValueChanged
          )
        }
        this.schema._onCellValueChangedInjected = true
      }
      this.schema.gridOptions.onMouseFocus = () => {
        this.gridApi?.stopEditing()
      }
    }
    if (_.isNil(this.value)) {
      this.value = []
    }
    this.rowData = _.cloneDeep(this.value)
  }

  private makeGridReady(func: any) {
    return (...args: any[]) => {
      this.onGridReady(args[0])
      func(...args)
    }
  }

  private makeCellValueChanged(func: any) {
    return (...args: any[]) => {
      this.onCellValueChanged(args[0])
      func(...args)
    }
  }

  private onCellValueChanged(event: CellEvent) {
    this.disableValueWatch++
    this.$set(this.value, event.rowIndex, _.cloneDeep(event.data))
    const value = this.value
    this.value = value
  }

  private onGridReady({ api }: { api: GridApi }) {
    this.colDefs = _.cloneDeep(this.columnDefs)
    api.setColumnDefs(this.colDefs)
    api.setRowData(this.rowData)
    if (!this.isMobile) {
      api.sizeColumnsToFit()
    }
    api.resetRowHeights()
  }
}
