import { Component, Vue } from 'vue-property-decorator'
import { userMappers } from '@/store/modules/user'
import { navBarTitleMapper } from '@/store/modules/navbar'
import PartnersAPI from '@/api/partners'
import _ from 'lodash'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['userCompany', 'isPartner'])
  },
  methods: {
    ...navBarTitleMapper.mapMutations(['setTitle'])
  }
})

@Component
export default class ResolveCompanyId extends Mappers {
  public getCompanyId(updateTitle = false) {
    let partnerId = null

    const query = (this.$route as any).query as any
    partnerId = query['partnerId']?.toString() ?? null

    if (partnerId) {
      if (updateTitle) {
        PartnersAPI.getPartner(partnerId).then(response => {
          if (response.status === 200) {
            this.setTitle(response.data.title)
          }
        })
      }
      return parseInt(partnerId)
    }

    const params = this.$route.params as any
    partnerId =
      params['companyId']?.toString() ?? params['partnerId']?.toString()

    if (partnerId && partnerId !== '-') {
      if (updateTitle) {
        PartnersAPI.getPartner(partnerId).then(response => {
          if (response.status === 200) {
            this.setTitle(response.data.title)
          }
        })
      }
      return parseInt(partnerId)
    }

    if (this.isPartner) {
      partnerId = this.userCompany.company.id
      if (partnerId && updateTitle) {
        this.setTitle(this.userCompany.company.title)
      }
      return partnerId
    }
    return null
  }

  public companyIdToURL(partnerId: string | number) {
    return partnerId === this.userCompany.company?.id
      ? '-'
      : _.isNumber(partnerId)
      ? partnerId
      : parseInt(partnerId)
  }
}
