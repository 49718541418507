import { Component, Vue } from 'vue-property-decorator'
import { clientBase } from '@/api/httpAxios'

@Component
export default class RouterEventWrapper extends Vue {
  public routerPush(url: string, newTab = false) {
    if (newTab) {
      window.open(`${clientBase}${url}`, '_blank')
      return
    }

    const urlBefore = url

    this.$router
      .push(
        urlBefore.startsWith(clientBase)
          ? urlBefore.replace(clientBase, '')
          : urlBefore
      )
      .catch(() => {
        return undefined
      })
  }
}
