import { NavigationGuardNext, Route } from 'vue-router'
import { routes } from '@/router/index'
import store from '@/store'
import Config from '@/config/config'
import { clientBase } from '@/api/httpAxios'
import { PermissionPageManager } from '@/api/permissions/pages'
import { permissions } from '@/api/permissions/permissions'
import { ConfigLocalStorage } from '@/config/localstorage'

export const beforeEachRoute = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  store.commit('navBarTitle/setFirstRoute', { to, from, next })
  // Change NavBar title
  store.commit(
    'navBarTitle/setTitleFromRoute',
    { width: window?.innerWidth ?? Config.defaultWidth, to: to },
    { root: true }
  )

  const isAuthed = store.getters['user/isAuthenticated']

  const abilities = PermissionPageManager.pages()
  const pageName: string = to.name ? to.name : ''
  const canViewThisPage = abilities.can(permissions.viewPage, pageName)

  // Change redirect URL
  routes[0].redirect = '/main-redirect'

  // if it's redirect, do redirect
  if (routes.find(r => r.redirect === to.path)) {
    return next()
  }

  if (!isAuthed) {
    // if this page without auth, redirect to page to.path
    if (canViewThisPage) {
      return next()
    } else {
      localStorage.setItem(ConfigLocalStorage.redirect, clientBase + to.path)
      return next(routes[0].redirect)
    }
  }

  if (isAuthed && !canViewThisPage && to.path !== '/error') {
    const user = store.getters['user/currentUser']
    const name =
      user && user.initials ? user.initials : 'Неизвестный пользователь'
    store.dispatch('toast/pushToast', {
      title: 'Авторизация',
      message: `Выполнена авторизация в системе от имени <b>${name}</b>. Данный пользователь не имеет прав на просмотр страницы.`
    })
  }

  if (!canViewThisPage) {
    return next('/error')
  }

  store.dispatch('notifications/fetchBellNotifications')
  next()
}
