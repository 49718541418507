
















import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

interface Params extends ICellRendererParams {
  [key: string]: any
}

@Component
export default class ActionRenderer extends Vue {
  params!: Params
  noActions = false
  noConfirm = false

  buttons = [] as {
    title: string
    icons: string[]
    condition: Function
    action: Function
    disabled?: boolean
  }[]

  private created() {
    if (this.params.data) {
      if (this.params.data._noActions) {
        this.noActions = true
      }
      if (this.params.data._noConfirm) {
        this.noConfirm = true
      }
    }
    this.rebuildButtons()
  }

  private checkType(type: string) {
    return this.params.getActionByType
      ? this.params.getActionByType(this.params).includes(type)
      : true
  }

  private rebuildButtons() {
    const buttons = [
      {
        title: this.params.onPrintText ?? 'Печать',
        icons: ['fas', 'print'],
        condition: () => {
          return this.params.onPrint && this.checkType('onPrint')
        },
        action: () => {
          this.params.onPrint(this.params)
        }
      },
      {
        title: this.params.onConfirmText ?? 'Подтвердить',
        icons: ['fas', 'check'],
        condition: () => {
          return this.params.onConfirm && this.checkType('onConfirm')
        },
        action: () => {
          this.params.onConfirm(this.params)
        },
        disabled: this.params.onConfirmDisabled
          ? this.params.onConfirmDisabled(this.params)
          : false
      },
      {
        title: this.params.onDownloadText ?? 'Скачать',
        icons: ['fas', 'file-download'],
        condition: () => {
          return this.params.onDownload && this.checkType('onDownload')
        },
        action: () => {
          this.params.onDownload(this.params)
        }
      },
      {
        title: this.params.onEditText ?? 'Редактировать',
        icons: ['fas', 'edit'],
        condition: () => {
          return this.params.onEdit && this.checkType('onEdit')
        },
        action: () => {
          this.params.onEdit(this.params)
        }
      },
      {
        title: this.params.onInfoText ?? 'Информация',
        icons: ['fas', 'info'],
        condition: () => {
          return this.params.onInfo && this.checkType('onInfo')
        },
        action: () => {
          this.params.onInfo(this.params)
        }
      },
      {
        title:
          this.params.data && this.params.data.isActive
            ? 'Заблокировать'
            : 'Разблокировать',
        icons:
          this.params.data && this.params.data.isActive
            ? ['fas', 'user-lock']
            : ['fas', 'lock-open'],
        condition: () => {
          return this.params.onBlock && this.checkType('onBlock')
        },
        action: () => {
          this.params.onBlock(this.params)
        }
      },
      {
        title: this.params.onCloneText ?? 'Скопировать',
        icons: ['fas', 'copy'],
        condition: () => {
          return this.params.onClone && this.checkType('onClone')
        },
        action: () => {
          this.params.onClone(this.params)
        }
      },
      {
        title: this.params.onLinkText ?? 'Открыть ссылку',
        icons: ['fas', 'external-link-alt'],
        condition: () => {
          return this.params.onLink && this.checkType('onLink')
        },
        action: () => {
          this.params.onLink(this.params)
        }
      },
      {
        title: this.params.onQuotesText ?? 'Цитирование',
        icons: ['fas', 'quote-right'],
        condition: () => {
          return this.params.onQuotes && this.checkType('onQuotes')
        },
        action: () => {
          this.params.onQuotes(this.params)
        }
      },
      {
        title: this.params.onCopyText ?? 'Найти похожие',
        icons: ['fas', 'clone'],
        condition: () => {
          return this.params.onCopy && this.checkType('onCopy')
        },
        action: () => {
          this.params.onCopy(this.params)
        }
      },
      {
        title: this.params.onWordText ?? 'Экспортировать в DOCX',
        icons: ['fas', 'file-word'],
        condition: () => {
          return this.params.onWord && this.checkType('onWord')
        },
        action: () => {
          this.params.onWord(this.params)
        }
      },
      {
        title: this.params.onUserText ?? 'Перейти к пользователю',
        icons: ['fas', 'user'],
        condition: () => {
          return this.params.onUser && this.checkType('onUser')
        },
        action: () => {
          this.params.onUser(this.params)
        }
      },
      {
        title: this.params.onChatText ?? 'К диалогу',
        icons: ['fa', 'comments'],
        condition: () => {
          return this.params.onChat && this.checkType('onChat')
        },
        action: () => {
          this.params.onChat(this.params)
        }
      },
      {
        title: this.params.onBanText ?? 'Заблокировать',
        icons: ['fa', 'ban'],
        condition: () => {
          return this.params.onBan && this.checkType('onBan')
        },
        action: () => {
          this.params.onBan(this.params)
        }
      },

      {
        title: this.params.onUpText ?? 'Вверх',
        icons: ['fas', 'arrow-up'],
        condition: () => {
          return (
            this.params.onUp &&
            this.params.rowIndex !== 0 &&
            this.checkType('onUp')
          )
        },
        action: () => {
          this.params.onUp(this.params)
        }
      },
      {
        title: this.params.onDownText ?? 'Вниз',
        icons: ['fas', 'arrow-up'],
        condition: () => {
          return (
            this.params.onDown &&
            this.params.rowIndex !== this.params.length - 1 &&
            this.checkType('onDown')
          )
        },
        action: () => {
          this.params.onDown(this.params)
        }
      },
      {
        title: this.params.onHideText ?? 'Скрыть вакансию',
        icons: ['fas', 'eye-slash'],
        condition: () => {
          return this.params.onHide && this.checkType('onHide')
        },
        action: () => {
          this.params.onHide(this.params)
        },
        disabled: this.params.onHideDisabled
          ? this.params.onHideDisabled(this.params)
          : false
      },
      {
        title: this.params.onRespondsText ?? 'Показать отклики',
        icons: ['fa', 'address-card'],
        condition: () => {
          return this.params.onResponds && this.checkType('onResponds')
        },
        action: () => {
          this.params.onResponds(this.params)
        }
      },
      {
        title: this.params.onRequestText ?? 'Создать заявку',
        icons: ['fas', 'file-invoice-dollar'],
        condition: () => {
          return this.params.onRequest && this.checkType('onRequest')
        },
        action: () => {
          this.params.onRequest(this.params)
        }
      },
      {
        title: this.params.onDeleteText ?? 'Удалить',
        icons: ['fas', 'times'],
        condition: () => {
          return this.params.onDelete && this.checkType('onDelete')
        },
        action: () => {
          this.params.onDelete(this.params)
        }
      }
    ]
    this.buttons = buttons.filter(b => b.condition())
  }
}
