const partnerPrefix = 'partner'

const keys = [
  'cookieAccept',
  'iKnowThatItIsDev',
  'redirect',
  'showKwsNotice'
]

export const ConfigLocalStorage: { [k: string]: string } = keys.reduce(
  (d, v) => ({ ...d, [v]: `${partnerPrefix}-${v}` }),
  {}
)

export const getLocalStorageKey = (key: string) =>
  window.localStorage.getItem(ConfigLocalStorage[key])

export const setLocalStorageKey = (key: string, value: any) =>
  window.localStorage.setItem(ConfigLocalStorage[key], value)
