import { systemNames } from '@orpk/orpk-vue-ts'

export default {
  urlsWithoutInterceptors: ['/general/logging/client'],
  recaptchaKey: '6Le9lrwZAAAAAA3o5C3vD8hoewxm02k8PYp5FNN-',
  systemTitle: {
    fullName: systemNames['partner-lk'].full,
    shortName: systemNames['partner-lk'].short
  },
  routing: {
    authPage: {
      path: '/auth',
      name: 'Авторизация в системе'
    },
    homePage: {
      path: '/main-page',
      name: 'О проекте'
    }
  },
  mobileWidth: 768,
  defaultWidth: 1000,
  defaultHeight: 920,
  universalLKLink: 'universal-lk/auth'
}
