








































import { Component, Vue, Watch } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import { userMappers } from '@/store/modules/user'
import { navBarTitle } from '@/store/modules/navbar'
import CounterAPI from '@/components/pages/home/components/float-button/helpers/requests'
import _ from 'lodash'
import { eventRouteNames, eventRouteURLs } from '@/router/pages/events'
import { newsRouteNames, newsRouteURLs } from '@/router/pages/news'
import { vacancyRouteNames, vacancyRouteURLs } from '@/router/pages/vacancies'
import { emManagerRouteNames, emManagerURLs } from '@/router/pages/em-manager'

const Mappers = Vue.extend({
  computed: {
    ...navBarTitle.mapGetters(['forceUpdateFloatButtonCount']),
    ...userMappers.mapGetters(['userCompany', 'isAuthenticated'])
  },
  methods: {
    ...navBarTitle.mapMutations([
      'setTotalCountFloatButton',
      'setForceUpdateFloatButtonCount'
    ])
  }
})

@Component({ mixins: [RouterEventWrapper] })
export default class FloatingButton extends Mappers {
  routerPush!: Function

  private interval?: ReturnType<typeof setInterval>

  private isDisplayMenu = false
  private totalCount = 0

  private options = [
    {
      name: 'Диалоги',
      field: 'unreadCount',
      icon: ['fa', 'comments'],
      count: 0,
      onClick: this.onClickDialogs
    },
    {
      name: 'Отклики на вакансию',
      field: 'vacancyResponds.count',
      icon: ['fa', 'briefcase'],
      count: 0,
      onClick: this.onClickVacancies
    },
    {
      name: 'Согласование мероприятий',
      field: 'awaitConfirmationEvents.count',
      icon: ['fa', 'trophy'],
      count: 0,
      onClick: this.onClickEvents
    },
    {
      name: 'Согласование новостей',
      field: 'awaitConfirmationNews.count',
      icon: ['fa', 'trophy'],
      count: 0,
      onClick: this.onClickNews
    },
    {
      name: 'Согласование вакансий',
      field: 'awaitCoordinationVacancies.count',
      icon: ['fa', 'briefcase'],
      count: 0,
      onClick: this.onClickVacanciesApprove
    },
    {
      name: 'Заявки на участие. Партнеры',
      field: 'awaitOrganizers.count',
      icon: ['fa', 'briefcase'],
      count: 0,
      onClick: this.onClickOrganizers
    },
    {
      name: 'Заявки на участие. Участники',
      field: 'awaitParticipants.count',
      icon: ['fa', 'briefcase'],
      count: 0,
      onClick: this.onClickOrganizers
    },
    {
      name: 'Заявки на удаление сотрудников',
      field: 'awaitUserDeletion.count',
      icon: ['fa', 'user-slash'],
      count: 0,
      onClick: this.onClickUserDelete
    },
    {
      name: 'Список жалоб',
      field: 'claims.count',
      icon: ['fas', 'th-list'],
      count: 0,
      onClick: this.onClickClaims
    },
    {
      name: 'Заявки на участие в проектах',
      field: 'applications',
      icon: ['fas', 'tasks'],
      count: 0,
      onClick: this.onClickApplications
    },
    {
      name: 'Заявки на участие в проектах',
      field: 'managerApplications',
      icon: ['fas', 'tasks'],
      count: 0,
      onClick: this.onClickMyProjects
    }
  ]

  private onClickDialogs() {
    this.isDisplayMenu = false
    this.routerPush('/dialogs/user')
  }

  private onClickVacancies() {
    this.isDisplayMenu = false
    if (this.userCompany.company.id) {
      this.routerPush(`/partner/${this.userCompany.company.id}/vacancies`)
    }
  }

  private onClickEvents() {
    this.isDisplayMenu = false
    this.routerPush(eventRouteURLs[eventRouteNames.EventEmManagerApproveRoute])
  }

  private onClickNews() {
    this.isDisplayMenu = false
    this.routerPush(newsRouteURLs[newsRouteNames.NewsEmManagerApproveRoute])
  }

  private onClickVacanciesApprove() {
    this.isDisplayMenu = false
    this.routerPush(
      vacancyRouteURLs[vacancyRouteNames.VacancyEmManagerApproveRoute]
    )
  }

  private onClickOrganizers() {
    this.isDisplayMenu = false
    this.routerPush(eventRouteURLs[eventRouteNames.EventEmManagerRequestRoute])
  }

  private onClickUserDelete() {
    this.isDisplayMenu = false
    this.routerPush(emManagerURLs[emManagerRouteNames.EmManagerUserDeleteRoute])
  }

  private onClickClaims() {
    this.isDisplayMenu = false
    this.routerPush(emManagerURLs[emManagerRouteNames.EmManagerComplaintRoute])
  }

  private onClickApplications() {
    this.isDisplayMenu = false
    this.routerPush('/partner/-/projects')
  }

  private onClickMyProjects() {
    this.isDisplayMenu = false
    this.routerPush('projects?filter=%7B"isMyProjects"%3Atrue%7D')
  }

  private async recalculateCount() {
    const response = await CounterAPI.getFloatButtonCount()

    let count = 0
    for (const option of this.options) {
      option.count = _.get(response.data, option.field, 0)
      count += option.count
    }

    this.totalCount = count
    this.setTotalCountFloatButton(this.totalCount)
  }

  private updateCount() {
    setTimeout(async () => {
      if (this.isAuthenticated) {
        await this.recalculateCount()
      }
    }, 200)
  }

  @Watch('forceUpdateFloatButtonCount')
  private onForceChanged() {
    this.updateCount()
    this.setForceUpdateFloatButtonCount(false)
  }

  @Watch('$route')
  private onRouteChange() {
    this.updateCount()
  }

  private getClassStyle() {
    return 'button-position'
  }

  private async created() {
    await this.recalculateCount()
    this.interval = setInterval(this.recalculateCount, 2 * 60 * 1000) // 2min
  }

  private beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
