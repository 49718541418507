




import { Vue, Component } from 'vue-property-decorator'
import HomeView from '@/views/HomeView.vue'

@Component({ components: { HomeView } })
export default class HomeDefault extends Vue {}
