























































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { notificationsMapper } from '@/store/modules/notifications'
import { notificationTypesTranslation } from '@/config/notificationTypes'
import dateFormat from '@/config/dateFormat'
import { Notification } from '@/types/interfaces/notifications'
import NotificationsAPI from '@/api/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapGetters(['bellNotifications', 'notifications'])
  },
  methods: {
    ...notificationsMapper.mapActions(['fetchBellNotifications'])
  }
})

@Component
export default class NotificationsModal extends Mappers {
  private mapper = notificationTypesTranslation

  private getFormattedTime(timestamp: string) {
    return timestamp
      ? moment(timestamp)
          .locale('ru')
          .format(dateFormat.date.message)
      : ''
  }
  private redirectToNotificationPage() {
    this.$bvModal.hide(`notificationsModal`)
    this.$router.push('/notifications')
  }
  private async setAsRead(note: Notification) {
    if (!note.isRead) {
      note.isRead = true
      if (this.notifications[note.type]) {
        const foundNote = this.notifications[note.type].find(
          (n: Notification) => n.id === note.id
        )
        if (foundNote) {
          foundNote.isRead = true
        }
      }
      await NotificationsAPI.changeReadStatus(note.id, true)
      await this.fetchBellNotifications()
    }
  }
}
