import { ifAuthenticated } from '@/router/before'

export const newsRouteNames = {
  NewsListRoute: 'NewsListRoute',
  NewsViewRoute: 'NewsViewRoute',
  NewsCardsRoute: 'NewsCardsRoute',
  NewsEmManagerApproveRoute: 'NewsEmManagerApproveRoute'
}
const rn = newsRouteNames

export const newsRouteURLs = {
  [rn.NewsListRoute]: '/partner/:partnerId/news',
  [rn.NewsViewRoute]: '/partner/:partnerId/news/:newsId',
  [rn.NewsCardsRoute]: '/news',
  [rn.NewsEmManagerApproveRoute]: '/news/approve'
}

export const newsPages = [
  {
    path: newsRouteURLs[rn.NewsListRoute],
    name: rn.NewsListRoute,
    meta: {
      sidebar: {
        icon: ['fas', 'newspaper'],
        isDisplay: true,
        routeBuildOptions: { partnerId: '-' }
      },
      navbar: {
        group: 'Новости',
        displayName: 'Новости организации'
      },
      breadcrumbs: {
        isDisplay: true,
        displayName: 'Новости'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "NewsListRoute" */ '@/views/news/NewsListRoute.vue'
      )
  },
  {
    path: newsRouteURLs[rn.NewsViewRoute],
    name: rn.NewsViewRoute,
    meta: {
      sidebar: null,
      navbar: {
        group: 'Новости',
        displayName: 'Страница новости'
      },
      breadcrumbs: null
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "NewsViewRoute" */ '@/views/news/NewsViewRoute.vue'
      )
  },
  {
    path: newsRouteURLs[rn.NewsCardsRoute],
    name: rn.NewsCardsRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'newspaper'],
        isDisplay: true
      },
      navbar: {
        group: 'Новости',
        displayName: 'Все новости'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "NewsCardsRoute" */ '@/views/news/NewsCardsRoute.vue'
      )
  },
  {
    path: newsRouteURLs[rn.NewsEmManagerApproveRoute],
    name: rn.NewsEmManagerApproveRoute,
    meta: {
      sidebar: {
        icon: ['fa', 'trophy'],
        isDisplay: true
      },
      navbar: {
        group: 'Новости',
        displayName: 'Согласование'
      }
    },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "NewsEmManagerApproveRoute" */ '@/views/news/NewsEmManagerApproveRoute.vue'
      )
  }
]
