import axios, { AxiosInstance } from 'axios'
import Config from '@/config/config.ts'
import * as Sentry from '@sentry/browser'
import { URLs } from '@orpk/orpk-vue-ts'

const partnerURLs = URLs['partner-lk']

export const baseURL =
  process.env.NODE_ENV === 'production'
    ? partnerURLs.production.serverURL
    : partnerURLs.local.serverURL

export const clientBase =
  process.env.NODE_ENV === 'production'
    ? partnerURLs.production.clientURL
    : partnerURLs.local.clientURL

export const socketPath = partnerURLs.production.socketPath
export const socketURL = partnerURLs.production.socketURL

export const http: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true
})

http.interceptors.request.use(config => {
  if (!Config.urlsWithoutInterceptors.includes(config.url!)) {
    const store = require('@/store/index').default
    store.commit('loading/enqueue', null, { root: true })
  }
  return config
})

function processSentry(error: any) {
  Sentry.withScope(scope => {
    if (error?.response) {
      scope.addBreadcrumb({
        category: 'response-data',
        message: JSON.stringify(error.response.data, null, 4)
      })
      scope.addBreadcrumb({
        category: 'status',
        message: error.response.status
      })
    }
    if (error?.config) {
      const scopeUrl = error.config.url?.replace(/[0-9]+/g, 'X') ?? ''
      const method = error.config.method
      const status = error.response?.status ?? '{no status}'
      error.message = `AxiosError ${status} ${method} ${scopeUrl}`
      scope.addBreadcrumb({
        category: 'request-data',
        message: error.config.data
      })
      scope.addBreadcrumb({
        category: 'path',
        message: error.config.url
      })
      scope.addBreadcrumb({
        category: 'method',
        message: error.config.method
      })
      scope.addBreadcrumb({
        category: 'scope',
        message: scopeUrl
      })
      scope.setFingerprint([
        error.config.method,
        scopeUrl,
        String(error.response?.status ?? 500)
      ])
    }
    if (error.response && error.response.status >= 401) {
      Sentry.captureException(error)
    } else {
      error.message = (error.message ?? '') + '[IGNORE]'
    }
  })
}

http.interceptors.response.use(
  response => {
    if (!Config.urlsWithoutInterceptors.includes(response.config.url!)) {
      const store = require('@/store/index').default
      store.commit('loading/dequeue', null, { root: true })
    }
    return response
  },
  error => {
    processSentry(error)
    if (
      error.config &&
      !Config.urlsWithoutInterceptors.includes(error.config.url!)
    ) {
      const store = require('@/store/index').default
      store.commit('loading/dequeue', null, { root: true })
      let data: any
      if (error.response?.data.constructor === ArrayBuffer) {
        try {
          data = JSON.parse(new TextDecoder().decode(error.response.data))
        } catch {
          data = {}
        }
      } else {
        data = error.response?.data ?? {}
      }
      store.dispatch('toast/pushToast', {
        error: true,
        title: 'Ошибка',
        message: data?.message || 'Ошибка сервера'
      })
      store.commit('logger/addLog', {
        userId: 1,
        severity: 'error',
        message: 'ERROR',
        page: window.location.pathname,
        error: {
          type: `axioserror${
            error.response?.status ? ` ${error.response?.status}` : ''
          }`,
          message: data?.message ?? '',
          stack: data?.stack ?? ''
        },
        timestamp: Date.now()
      })
      store.dispatch('logger/sendLogs')
    }
    throw error
  }
)

http.interceptors.response.use(
  response => response,
  error => {
    if (
      error.config &&
      !Config.urlsWithoutInterceptors.includes(error.config.url!)
    ) {
      if (error.response?.data?.isLoggedIn === false) {
        const store = require('@/store/index').default
        store.commit('user/setBadLogin', true, { root: true })
      }
    }
    throw error
  }
)
