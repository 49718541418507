import { AxiosPromise } from 'axios'
import { http } from '@/api/httpAxios'

export default class PartnerEducationProgramsAPI {
  public static getUniqueSpecialities(): AxiosPromise {
    return http.get('/companies/unique-specialties')
  }

  public static updateSpecialties(companyId: number, data: any): AxiosPromise {
    return http.put(`/companies/${companyId}/specialties`, data)
  }

  public static getSpecialtiesPartner(companyId: number): AxiosPromise {
    return http.get(`/companies/${companyId}/specialties`)
  }
}
