import { Route } from 'vue-router'
import { Vue } from 'vue-property-decorator'
import Config from '@/config/config'

export function afterEachRoute(to: Route) {
  const name = to?.meta?.navbar?.displayName
    ? ' - ' + to.meta.navbar.displayName
    : ''
  Vue.nextTick(() => {
    document.title = `${Config.systemTitle.shortName}${name}`
  })
}
