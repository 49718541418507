import { http, baseURL } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'
import { permissionsPartner } from '@/api/permissions/CommonPartnerPermissions'

export interface User {
  initials: string
  id: number
  surname: string
  name: string
  midname: string
  nameMidname?: string
  roles: string[]
  email: string
  personalNumber: number
  aisDepartments: any[]
  isDepHeadRights: boolean
  depHeaded: any
  userDepartments: any[]
  educations: any[]
  companyLink: {
    position: string
    company: {
      id: number
      title: string
      url: string
      permissions: permissionsPartner[]
    }
  }
  companies: any[]
  bannedAt: Date
  banReason: string | null
  actualUser?: User
  academicDegree: { id: number; title: string; shortTitle: string }[]
  academicTitle: { id: number; title: string }[]
  hasCuratedProjects: boolean
}

export default class UserAPI {
  public static getCurrentUser(
    reload = false
  ): AxiosPromise<{ user: User | null }> {
    const params = new URLSearchParams()
    if (reload) {
      params.append('reload', 'true')
    }
    return http.get('/auth/current-user', { params })
  }

  public static changeUser(userId: number) {
    window.location.href = `${baseURL}/auth/dev?userId=${userId}`
  }

  public static logout() {
    return http.get('/auth/logout')
  }

  public static validateClientRoutes(routes: any) {
    return http.post('/routes/client/validate', {
      routes: JSON.stringify(routes)
    })
  }
}
