import { AxiosPromise } from 'axios'
import { http, clientBase } from '@/api/httpAxios'

export interface BuildInfo {
  commit: string
  branch: string
  time: string
}

export default class InfoAPI {
  public static getServerBuildInfo(): AxiosPromise<BuildInfo> {
    return http.get('/general/service/build/server')
  }

  public static getClientBuildInfo(): AxiosPromise<BuildInfo> {
    return http.get('/_build.json', {
      baseURL: clientBase
    })
  }

  public static getClientBuildFromServer(): AxiosPromise<BuildInfo> {
    return http.get('/general/service/build/client')
  }
}
