export enum notificationTypes {
  vacancy = 'vacancy',
  project = 'project',
  misc = 'misc',
  chat = 'chat',
  claim = 'claim',
  approval = 'approval'
}

export const notificationTypesTranslation: { [k: string]: string } = {
  [notificationTypes.vacancy]: 'Вакансии',
  [notificationTypes.project]: 'Участие в проектах',
  [notificationTypes.chat]: 'Диалоги',
  [notificationTypes.claim]: 'Жалобы',
  [notificationTypes.approval]: 'Согласование',
  [notificationTypes.misc]: 'Другое'
}

export const notificationTypesTabs: { [k: string]: string } = {
  [notificationTypes.vacancy]: 'Вакансии',
  [notificationTypes.project]: 'Участие в проектах',
  [notificationTypes.chat]: 'Диалоги',
  [notificationTypes.claim]: 'Жалобы',
  [notificationTypes.approval]: 'Согласование',
  [notificationTypes.misc]: 'Другое'
}

export enum notificationDocumentSubscriptionTypes {
  status = 'status',
  all = 'all',
  none = 'none'
}

export const LIMIT_PAGINATION_LIST = 5
export const LIMIT_PAGINATION_BELL = 5
