import { Getters, Mutations, Module, createMapper } from 'vuex-smart-module'
import Config from '@/config/config'
import _ from 'lodash'

class NavBarTitleState {
  title = Config.systemTitle.fullName
  subTitle = ''
  firstEntry = {
    to: null,
    from: null,
    next: null
  } as any

  // Float button
  totalCountFloatButton = 0
  forceUpdateFloatButtonCount = false
}

class NavBarTitleGetters extends Getters<NavBarTitleState> {
  get navBarTitle() {
    return this.state.title
  }
  get navBarSubTitle() {
    return this.state.subTitle
  }
  get forceUpdateFloatButtonCount() {
    return this.state.forceUpdateFloatButtonCount
  }

  buildTitle(isMobile: boolean) {
    if (
      this.state.title === Config.systemTitle.fullName &&
      this.state.subTitle === Config.routing.authPage.name
    ) {
      if (isMobile) {
        return Config.systemTitle.shortName
      } else {
        return this.state.title
      }
    } else {
      if (isMobile) {
        return this.state.subTitle
      } else {
        return `${this.state.title}${
          this.state.subTitle ? '. ' + this.state.subTitle : this.state.subTitle
        }`
      }
    }
  }
}

class NavBarTitleMutations extends Mutations<NavBarTitleState> {
  setTitleFromRoute(info: any) {
    const firstPart = info.to.meta.navbar?.group
    const secondPart = info.to.meta.navbar?.displayName

    // Is Auth Page

    this.state.title = firstPart || Config.systemTitle.fullName
    this.state.subTitle = secondPart || ''
  }

  setTitle(text: string) {
    this.state.title = text
  }
  setSubTitle(text: string) {
    this.state.subTitle = text
  }
  setFirstRoute(info: any) {
    const { to, from, next } = info
    if (
      _.isEmpty(this.state.firstEntry.to) &&
      to.fullPath !== Config.routing.authPage.path
    ) {
      this.state.firstEntry.to = to
      this.state.firstEntry.from = from
      this.state.firstEntry.next = next
    }
  }

  setTotalCountFloatButton(n: number) {
    this.state.totalCountFloatButton = n
  }

  setForceUpdateFloatButtonCount(b: boolean) {
    this.state.forceUpdateFloatButtonCount = b
  }
}

export const navBarTitle = new Module({
  state: NavBarTitleState,
  getters: NavBarTitleGetters,
  mutations: NavBarTitleMutations
})

export const navBarTitleMapper = createMapper(navBarTitle)
