















import { Vue, Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import { BModal } from 'bootstrap-vue'
import { notificationsMapper } from '@/store/modules/notifications'
import { MaintenanceInfo } from 'interfaces/notifications'

const Mappers = Vue.extend({
  computed: {
    ...notificationsMapper.mapState(['maintenanceInfo'])
  }
})

@Component
export default class MaintenanceModal extends Mappers {
  $refs!: { modal: BModal }
  private maintenanceMessage = ''
  private showReload = false

  @Watch('maintenanceInfo', { deep: true })
  private onMaintenanceUpdate(data: MaintenanceInfo | null) {
    if (data) {
      this.maintenanceMessage = data.message
      this.showReload = data.showReload ?? false
      if (_.isEmpty(data.time) || moment().isSameOrBefore(moment(data.time))) {
        this.$refs.modal.show()
      }
    }
  }

  private onReload() {
    location.reload(true)
  }
}
