import { NavigationGuard } from 'vue-router'
import store from '@/store'
import { logger } from '@/store/modules/logger'
import _ from 'lodash'

export const ifAuthenticated: NavigationGuard = (to, from, next) => {
  if (store.getters['user/isAuthenticated']) {
    const loggerState = logger.context(store)
    loggerState.mutations.addLog({
      userId: !_.isUndefined(store.state.user.user)
        ? store.state.user.user.id
        : null,
      severity: 'info',
      message: 'NEW PAGE',
      page: to.fullPath,
      timestamp: Date.now()
    })
  }
  return next()
}
